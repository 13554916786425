import { Grid } from '@mui/material'
import { useRef, useState } from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { GoogleMap, Marker, Autocomplete } from '@react-google-maps/api'
import MagicLoader from '../../components/atoms/MagicLoader/MagicLoader'

const TourPage = () => {
  const ref = useRef()
  const style = {
    box: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      flexDirection: 'column',
      maxWidth: '300px',
    },
    button: {
      width: '100%',
      marginTop: '20px',
    },
    buttonPrimary: {
      width: '100%',
      marginTop: '20px',
      background: '#00CAE6',
    },
    input: {
      width: '100%',
      border: 'none',
      background: 'white !important',
      borderRadius: '5px',
      height: '29px',
      boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    },
    title: {
      marginTop: '100px',
      marginBottom: '20px',
      fontSize: '22px',
      fontWeight: '600',
      textTransform: 'uppercase'
    },
    desc: {
      fontSize: '18px'
    },
    desc1: {
      marginTop: '10px',
      fontSize: '18px'
    }
  }
  const [data, setData] = useState([])
  const [address, setAddress] = useState(null)
  const [map, setMap] = useState(null)
  const addAddressToList = () => {
    if (address?.length > 0) {
      const newArr = [...data]
      newArr.push({ address: address })
      setData(newArr)
      ref.current.value = ''
    }
  }
  const clearList = () => {
    setData([])
  }
  const mergeAddress = async () => {
    const tempData = data
    const place1 = 'ul. Bolesław Chrobrego 18, Koszalin'
    const place2 = 'ul. Chałubińskiego 10C, Koszalin'
    for (const el of tempData) {
      const index = tempData.indexOf(el)
      tempData[index].destination2 = 23
      // eslint-disable-next-line no-undef
      const directionsService = new google.maps.DirectionsService()
      // place1
      const results1 = await directionsService.route({
        origin: place1,
        destination: tempData[index].address,
        // eslint-disable-next-line no-undef
        travelMode: google.maps.TravelMode.DRIVING,
      })
      tempData[index].distance1 = results1.routes[0].legs[0].distance.value
      tempData[index].distance1Text = results1.routes[0].legs[0].distance.text
      tempData[index].duration1 = results1.routes[0].legs[0].duration.value
      tempData[index].duration1Text = results1.routes[0].legs[0].duration.text
      // place2
      const results2 = await directionsService.route({
        origin: place2,
        destination: tempData[index].address,
        // eslint-disable-next-line no-undef
        travelMode: google.maps.TravelMode.DRIVING,
      })
      tempData[index].distance2 = results2.routes[0].legs[0].distance.value
      tempData[index].distance2Text = results2.routes[0].legs[0].distance.text
      tempData[index].duration2 = results2.routes[0].legs[0].duration.value
      tempData[index].duration2Text = results2.routes[0].legs[0].duration.text
      if (tempData[index].distance2 < tempData[index].distance1) {
        tempData[index].goTo = 'UNO1'
      } else {
        tempData[index].goTo = 'UNO2'
      }
    }
    await setData(null)
    setData(tempData)
  }
  return (
    <div>
      <Box sx={style.box}>
         <Autocomplete className="autocomplete">
          <input
            ref={ref}
            type="text"
            style={style.input}
            placeholder="Dodaj adres do listy"
            onChange={(e) => setAddress(e.target.value)}
            onBlur={(e) => setAddress(e.target.value)}
            onKeyUp={(e) => {
              if (e?.charCode === 13) addAddressToList()
              setAddress(e.target.value)
            }}
          />
         </Autocomplete>
        <Button variant="contained" sx={style.button} onClick={() => addAddressToList()}>Dodaj do listy</Button>
        <Button variant="contained" sx={style.button} onClick={() => clearList()}>Wyczyść listę</Button>
        <Button variant="contained" sx={style.buttonPrimary} onClick={() => mergeAddress()}>Z jakiej restauracji?</Button>
      </Box>
      <Typography sx={style.title}>Lista wszystkich adresów na liście</Typography>
      <Grid container spacing={4}>
        <MagicLoader variable={data?.length} count={10}>
          {
            data?.length > 0
              ? data?.map((el, index) => (
                <Grid key={index} item md={4}>
                  <Typography sx={style.desc}>
                    <b>Adres: </b><br/>{el.address}
                  </Typography>
                  {
                    el?.goTo && (
                      <Typography sx={style.desc1}>
                        <b>Zamówienie z: </b>
                        {el?.goTo}
                      </Typography>
                    )
                  }
                  { el?.goTo && <Typography sx={style.desc}>{el.goto}</Typography> }
                  { el?.goTo === 'UNO1' && el?.distance1Text && <Typography sx={style.desc}><b>Odległość: </b> {el.distance1Text}</Typography> }
                  { el?.goTo === 'UNO2' && el?.distance2Text && <Typography sx={style.desc}><b>Odległość: </b>{el.distance2Text}</Typography> }
                  { el?.goTo === 'UNO1' && el?.duration1Text && <Typography sx={style.desc}><b>Czas dojazdu: </b>{el.duration1Text}</Typography> }
                  { el?.goTo === 'UNO2' && el?.duration2Text && <Typography sx={style.desc}><b>Czas dojazdu: </b>{el.duration2Text}</Typography> }
                </Grid>
              ))
              : (
                <Grid item md={12}>
                  <Typography sx={style.desc}>
                    Póki co brak dodanych adresów
                  </Typography>
                </Grid>
              )
          }
        </MagicLoader>
      </Grid>
      <Grid container>
        <Grid item md={12}>
          <GoogleMap
            center={{ lat: 48.8584, lng: 2.2945 }}
            zoom={15}
            mapContainerStyle={{ width: '100%', height: '500px', marginTop: '50px' }}
            options={{
              zoomControl: false,
              streetViewControl: false,
              mapTypeControl: false,
              fullscreenControl: false,
            }}
            onLoad={(map) => setMap(map)}
          >
            <Marker position={{ lat: 48.8584, lng: 2.2945 }}/>
          </GoogleMap>
        </Grid>
      </Grid>
    </div>
  )
}

export default TourPage
