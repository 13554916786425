import { Helmet } from 'react-helmet'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import cloud from '../../assets/images/bgElements/clouds.png'
import space from '../../assets/images/bgElements/space.png'
import stars from '../../assets/images/bgElements/stars.png'
import regulations from '../../assets/pdf/regulations.pdf'
import rodo from '../../assets/pdf/rodo.pdf'
import policy from '../../assets/pdf/policy.pdf'

const HomePage = () => {
	return (
		<Box
			sx={{
				width: '100%',
			}}
		>
			 <Helmet>
				<meta charSet="utf-8" />
				<title>Pizzeria UNO Koszalin - Katastrofa na waszej planecie okazała się pięknym wydarzeniem. Pokażemy wam Kosmicznie Dobrą Pizzę</title>
				<meta name="keywords" content="pizzeria, uno, koszalin"/>
				<meta name="description" content="Katastrofa na waszej planecie okazała się pięknym wydarzeniem. Pokażemy wam Kosmicznie Dobrą Pizzę" />
				<meta name="subject" content="Planszówki Koszalin | Klub planszówkowy | Koszalin"/>
				<meta name="copyright" content="Pizzeria UNO Koszalin"/>
				<meta name="language" content="PL"/>
				<meta name="robots" content="index,follow" />
				<meta name="author" content="Jan Szymański, kontakt@your-site.pl"/>
				<meta name="designer" content="Jan Szymański, kontakt@your-site.pl"/>
				<meta name="copyright" content="Jan Szymański, kontakt@your-site.pl"/>
				<meta name="owner" content="Jan Szymański, kontakt@your-site.pl"/>
				<meta name="url" content="https://unokoszalin.pl/"/>
				<meta name="identifier-URL" content="https://unokoszalin.pl/"/>
				<meta name="category" content="Pizzeria"/>
				<meta name="distribution" content="Global"/>
				<meta name="rating" content="General"/>
				<meta name="revisit-after" content="7 days"/>
				<meta httpEquiv="Expires" content="0"/>
				<meta httpEquiv="Pragma" content="no-cache"/>
				<meta httpEquiv="Cache-Control" content="no-cache"/>
				<meta name="og:title" content="Pizzeria UNO Koszalin - Katastrofa na waszej planecie okazała się pięknym wydarzeniem. Pokażemy wam Kosmicznie Dobrą Pizzę"/>
				<meta name="og:type" content="website"/>
				<meta name="og:url" content="https://planszowki-koszalin.pl"/>
				<meta name="og:image" content="http://localhost:3000/static/media/website1.9f1b6babd1b88a4cad9e.jpg"/>
				<meta name="og:site_name" content="Pizzeria UNO Koszalin"/>
				<meta name="og:description" content="Katastrofa na waszej planecie okazała się pięknym wydarzeniem. Pokażemy wam Kosmicznie Dobrą Pizzę"/>
			  <meta name="fb:page_id" content="unokoszalin" />
				<meta name="og:email" content="koszalinuno@gmail.com"/>
				<meta name="og:phone_number" content="576283201"/>
				<meta name="og:locality" content="Koszalin"/>
				<meta name="og:region" content="Zachodniopomorskie"/>
				<meta name="og:postal-code" content="75-063"/>
				<meta name="og:country-name" content="Polska"/>
				<meta property="og:type" content="website"/>
				<meta name="apple-mobile-web-app-capable" content="yes"/>
				<meta content="yes" name="apple-touch-fullscreen" />
				<meta name="apple-mobile-web-app-status-bar-style" content="black"/>
				<meta name="format-detection" content="telephone=no"/>
			 </Helmet>
			<Box
				sx={{
					width: '100%',
					background: 'linear-gradient(180deg,rgba(0,0,0,0.2) 0,rgba(0, 177, 254, 0.2) 100%), #030b24',
					position: 'relative',
					minHeight: BreakpointsHelper.checkSizeWindow('md', 'up') ? '100vh' : BreakpointsHelper.checkSizeWindow('sm', 'down') ? 'calc(100vh - 300px)' : BreakpointsHelper.checkSizeWindow('xs', 'down') ? 'calc(100vh - 100px)' : 'calc(100vh - 200px)',
				}}
			>
				<Typography
					sx={{
						paddingTop: BreakpointsHelper.isMobile() ? '50px' : '300px',
						textAlign: 'center',
						fontSize: BreakpointsHelper.isMobile() ? '32px' : '82px',
						lineHeight: BreakpointsHelper.isMobile() ? '32px' : '82px',
						fontWeight: '900',
						zIndex: 2,
						position: 'relative',
						color: 'white',
						textTransform: 'uppercase',
					}}
				>
					Kosmiczne<br/>Placki
				</Typography>
				<Typography
					sx={{
						marginTop: '21px',
						textAlign: 'center',
						letterSpacing: '0.15px',
						zIndex: 2,
						paddingBottom: '300px',
						position: 'relative',
						fontSize: BreakpointsHelper.isMobile() ? '20px' : '40px',
						lineHeight: BreakpointsHelper.isMobile() ? '24px' : '48px',
						color: '#BCBEC0',
					}}
				>
					Przybyliśmy, abyście poznali smak pizzy nie
					z tego świata!
				</Typography>
				<img
					src={space}
					alt="space"
					style={{
						width: '100%',
						position: 'fixed',
						zIndex: '1',
						maxWidth: '100%',
						top: BreakpointsHelper.checkSizeWindow('md', 'up') ? '650px' : BreakpointsHelper.checkSizeWindow('sm', 'down') ? '400px' : BreakpointsHelper.checkSizeWindow('xs', 'down') ? '300px' : '500px',
					}}
				/>
				<img
					src={stars}
					alt="stars"
					style={{
						width: '100%',
						position: 'fixed',
						zIndex: '0',
						top: '100px',
					}}
				/>
				<img
					src={cloud}
					alt="cloud"
					style={{
						width: '100%',
						position: 'absolute',
						maxWidth: '100%',
						zIndex: '2',
					}}
				/>
			</Box>
			<Box
				sx={{
					padding: '20px',
					display: 'flex',
					minHeight: '400px',
					position: 'relative',
					zIndex: '2',
					background: 'rgb(3, 11, 36)',
					flexDirection: 'column',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						alignItems: BreakpointsHelper.isMobile() ? 'flex-start' : 'center',
						justifyContent: 'center',
						flexDirection: BreakpointsHelper.isMobile() && 'column',
						height: '80px',
					}}
				>
					<Typography
						sx={{
							marginRight: '10px',
							marginLeft: '10px',
							cursor: 'pointer',
						}}
					>
						@ {(new Date()).getFullYear()}, Uno Koszalin <a href="https://your-site.pl" target="_blank" rel="noreferrer">Your site</a>.
					</Typography>
					<Typography
						sx={{
							marginRight: '10px',
							marginLeft: '10px',
							cursor: 'pointer',
						}}
					>
						<a href={regulations} target="_blank" rel="noreferrer">
							Regulamin serwisu
						</a>
					</Typography>
					<Typography
						sx={{
							marginRight: '10px',
							marginLeft: '10px',
							cursor: 'pointer',
						}}
					>
						<a href={policy} target="_blank" rel="noreferrer">
							Polityka prywatności
						</a>
					</Typography>
					<Typography
						sx={{
							marginRight: '10px',
							marginLeft: '10px',
							cursor: 'pointer',
						}}
					>
						<a href={rodo} target="_blank" rel="noreferrer">
							Obowiązek informacyjny
						</a>
					</Typography>
				</Box>
			</Box>
		</Box>
  )
}

export default HomePage
