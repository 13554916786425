const OrderPage = () => {
  const style = {
    iframe: {
      width: 'calc(100% - 10px)',
      height: 'calc(100vh - 125px)',
      overflow: 'hidden',
    }
  }
  return <iframe className="iframe" frameBorder="0" style={style.iframe} src='https://unokoszalin.goorder.pl/'/>
}

export default OrderPage
