import Pizza from '../../components/atoms/Pizza/Pizza'
import Grawitacja from '../../assets/images/pizza/grawitacja.png'
import Obcy from '../../assets/images/pizza/obcy.png'
import Apollo13 from '../../assets/images/pizza/apollo13.png'
import WStroneSlonca from '../../assets/images/pizza/wStroneSlona.png'
import OdysejaKosmiczna from '../../assets/images/pizza/odysejaKosmiczna.png'
import ET from '../../assets/images/pizza/e.t..png'
import Predator from '../../assets/images/pizza/predator.png'
import NaSkrajuJutra from '../../assets/images/pizza/naSkrajuJutra.png'
import PodrozNaKsiezyc from '../../assets/images/pizza/podrozNaKsiezyc.png'
import NowyPoczatek from '../../assets/images/pizza/nowyPoczatek.png'
import Mglawica from '../../assets/images/pizza/mglawica.png'
import SuperNova from '../../assets/images/pizza/superNova.png'
import UkrytyWymiar from '../../assets/images/pizza/ukrytyWymiar.png'
import AleUrwal from '../../assets/images/pizza/aleUrwal.png'
import { Grid } from '@mui/material'

const BuyPage = () => {
  const pizzas = [
    {
      name: 'Grawitacja',
      image: Grawitacja,
      price: 23,
      ingredients: 'Sos, Mozzarella, Świeża Bazylia'
    },
    {
      name: 'Obcy',
      image: Obcy,
      price: 29,
      ingredients: 'Sos, Mozzarella, Salami, Szalotka'
    },
    {
      name: 'Apollo13',
      image: Apollo13,
      price: 28,
      ingredients: 'Sos, Mozzarella, Prosciutto Cotto, Pieczarki'
    },
    {
      name: 'W Stronę Słońca',
      image: WStroneSlonca,
      price: 34,
      ingredients: 'Sos, Mozzarella, Mascarpone, Prosciutto Crudo, Rukola'
    },
    {
      name: 'Odyseja Kosmiczna',
      image: OdysejaKosmiczna,
      price: 34,
      ingredients: 'Sos, Mozzarella, Gorgonzola, Camembert, Feta'
    },
    {
      name: 'E.T.',
      image: ET,
      price: 34,
      ingredients: 'Sos, Mozzarella, Kurczak, Szalotka, Szczypiorek, Mascarpone'
    },
    {
      name: 'Predator',
      image: Predator,
      price: 35,
      ingredients: 'Sos, Mozzarella, Pancetta, Prosciutto Cotto, Salami, Szalotka'
    },
    {
      name: 'Na Skraju Jutra',
      image: NaSkrajuJutra,
      price: 31,
      ingredients: 'Sos, Mozzarella, Salami Spianata, Jalapeno, Szalotka - 2 Stopnie Ostrości'
    },
    {
      name: 'Podróż Na Księżyc',
      image: PodrozNaKsiezyc,
      price: 35,
      ingredients: 'Sos, Mozzarella, Pomidorki Koktajlowe, Rukola, Mozzarella Di Bufala'
    },
    {
      name: 'Nowy Początek',
      image: NowyPoczatek,
      price: 35,
      ingredients: 'Sos, Wegańska Mozzarella, Wegański Cheddar, Cukinia, Bakłażan, Suszone Pomidory, Czerwona Cebula'
    },
    {
      name: 'Mgławic',
      image: Mglawica,
      price: 38,
      ingredients: 'Sos Beszamelowy, Świeży Szpinak, Mozzarella, Cebula Czerwona, Krewetki'
    },
    {
      name: 'Super Nova',
      image: SuperNova,
      price: 36,
      ingredients: 'Sos Beszamelowy, Miód, Mozzarella, Gruszka, Orzechy Włoskie, Rukola, Gorgonzola'
    },
    {
      name: 'Ukryty Wymiar',
      image: UkrytyWymiar,
      price: 35,
      ingredients: 'Sos BBQ, Mozzarella , Kurczak, Salami, Jalapeno, Cebula Czerwona'
    },
    {
      name: 'Ale Urwał',
      image: AleUrwal,
      price: 37,
      ingredients: 'BBQ, Bekon, Gorgonzola, Chorizo, Chutney, Nachos'
    },
  ]
  return (
    <div>
      <Grid container spacing={4}>
        {
          pizzas?.map((el, index) => (
            <Grid key={index} item md={4}>
              <Pizza
                name={el.name}
                image={el.image}
                price={el.price}
                ingredients={el.ingredients}
              />
            </Grid>
          ))
        }
      </Grid>
    </div>
  )
}

export default BuyPage
