import React, { useState } from 'react'
import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'
import LocalStorageHelper from '../../../helpers/LocalStorageHelper/LocalStorageHelper'
import ChangeLang from '../../organisms/ChangeLang/ChangeLang'
import { Grid, Tooltip, useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import MenuIcon from '@mui/icons-material/Menu'
import Button from '@mui/material/Button'

const NavigationPreview = (props) => {
  const { location } = props
  const config = useTheme().config
  const t = LocalStorageHelper.get('translation')
  const [open, setOpen] = useState(false)
  const style = {
    nav: {
      position: 'fixed',
      top: 0,
      right: 0,
      width: `${BreakpointsHelper.isMobile() ? 'calc(100% - 30px)' : 'calc(100% - 50px)'}`,
      margin: '0 auto',
      height: '100px',
      background: '#030b24',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingRight: BreakpointsHelper.isMobile() ? 15 : 25,
      paddingLeft: BreakpointsHelper.isMobile() ? 15 : 25,
      zIndex: 999,
      boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
    },
    logo: {
      height: '55px',
    },
    logoOpen: {
      height: BreakpointsHelper.isMobile() ? '55px' : '100%',
      marginLeft: BreakpointsHelper.isMobile() ? '0' : '35px',
      position: 'relative',
      left: '-5px',
      bottom: '-3px',
    },
    buttons: {
      display: 'flex',
      alignItems: 'center',
    },
    burgerOpen: {
      height: '50px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      position: 'relative',
      right: '-5px',
      bottom: '-2px',
      marginBottom: '30px',
    },
    menu: {
      width: 40,
      height: 40,
      color: 'white',
      cursor: 'pointer',
    },
  }
  const openLink = (link) => location.history.push(link)
  return (
		<div style={style.nav} className="navigation">
      <img src={config.logo} alt={'logo'} style={style.logo} />
      {
        BreakpointsHelper.isMobile()
        ? (
          <div onClick={() => setOpen(!open)}>
            <MenuIcon style={style.menu}/>
          </div>
        )
        : (
          <div style={style.buttons}>
            <Button
              variant="contained"
              sx={{ margin: 0, marginRight: '20px', height: '37px', background: '#00CAE6', color: 'white', fontWeight: '600' }}
              onClick={() => openLink('/login')}
            >
              {t?.homePage?.buttonApp}
            </Button>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <ChangeLang noMargin />
            </Box>
          </div>
        )
      }
      {
        open && (
          <Box
            sx={{
              position: 'absolute',
              zIndex: '999',
              background: 'white',
              top: '0',
              left: '0',
              height: '100vh',
              display: 'flex',
              width: '100%',
              flexDirection: 'column',
            }}
          >
            <Box sx={{ background: '#030b24', padding: '20px 20px 0 20px' }}>
              <Grid container>
                <Grid item xs={6}>
                  <img src={config.logo} alt={'logo'} style={style.logoOpen} />
                </Grid>
                <Grid item xs={6}>
                  <div style={style.burgerOpen} onClick={() => setOpen(!open)}>
                    <MenuIcon style={style.menu}/>
                  </div>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ padding: '20px' }}>
              <Button
                variant="contained"
                fullWidth
                sx={{ marginBottom: '20px', marginTop: '10px' }}
                onClick={() => openLink('/login')}
              >
                {t?.homePage?.buttonApp}
              </Button>
              <Tooltip title={t?.globals?.nav?.langChange} placement="right">
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <ChangeLang noMargin />
                </Box>
              </Tooltip>
            </Box>
          </Box>
        )
      }
		</div>
  )
}

export default NavigationPreview
