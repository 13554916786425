import React from 'react'
import { FormGroup, TextField } from '@mui/material'
import PropTypes from 'prop-types'
import StringHelper from '../../../../helpers/StringHelper/StringHelper'

const Input = ({ label, name, variant, helperText, error, type, required, value, onChange, onBlur, onKeyUp, disabled }) => {
  const style = {
    root: {
      marginTop: '5px',
      marginBottom: '15px',
      width: '100%',
      color: 'white',
    },
    error: {
      color: '#d32f2f',
      fontSize: '13px',
      marginLeft: '15px',
      marginTop: '10px',
    },
  }
  const generateLabel = () => StringHelper.toCapitalize(label)
  return (
		<FormGroup style={style.root}>
			<TextField
				error={Boolean(error)}
				name={name}
				disabled={disabled}
				type={type || 'text'}
				label={generateLabel()}
				value={value}
				onChange={onChange}
				onBlur={onBlur}
				helperText={helperText}
				variant={variant}
				required={required}
				onKeyUp={onKeyUp}
				autoComplete="chrome-off"
        sx={{
          borderColor: 'white',
          color: 'white',
        }}
			/>
			{
				error && (
					<div style={style.error}>
						{StringHelper.toCapitalize(error)}
					</div>
				)
			}
		</FormGroup>
  )
}

Input.defaultProps = {
  variant: 'outlined',
  helperText: '',
  value: '',
  error: null,
  required: true,
  disabled: false,
  shrink: false,
  onChange: null,
  onBlur: null,
  onKeyUp: null,
}

Input.propsType = {
  type: PropTypes.string.isRequired,
  variant: PropTypes.string,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyUp: PropTypes.func,
  name: PropTypes.string.isRequired,
  error: PropTypes.object || PropTypes.string,
  required: PropTypes.bool,
}

export default Input
