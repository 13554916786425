import Typography from '@mui/material/Typography'
import { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import MagicLoader from '../../components/atoms/MagicLoader/MagicLoader'
import Code from '../../components/atoms/Code/Code'
import { getAllCodes } from '../../firebase'

const CodesPage = () => {
  const [codes, setCodes] = useState([])
  const handleCodes = async () => {
    return await getAllCodes().then(async res => {
      if (res) {
        const tempData = []
        await res?.map(el => {
          const data = el?.data
          data.id = el?.id
          if (data) {
            tempData.push(data)
          }
          return true
        })
        tempData.reverse()
        if (codes?.length !== tempData?.length) {
          await setCodes(tempData)
        }
      }
    })
  }
  useEffect(() => {
    handleCodes()
  }, [])
  return (
    <div>
      <Typography sx={{ marginBottom: '47px', fontWeight: '800', fontSize: '22px' }}>Kupony</Typography>
      <MagicLoader variable={codes}>
        <Grid container spacing={4}>
          {
            codes?.map((el, index) => (
              <Grid key={index} item md={2} xs={12}>
                <Code data={el} />
              </Grid>
            ))
          }
        </Grid>
      </MagicLoader>
    </div>
  )
}

export default CodesPage
