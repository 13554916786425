import * as React from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import PrimarySearchAppBar from '../PrimarySearchAppBar/PrimarySearchAppBar'
import Navigation from '../../molecules/Navigation/Navigation'
import { useState } from 'react'
import {
  HomeOutlined,
  LocationOnOutlined,
  NewspaperOutlined,
  ShoppingCartOutlined
} from '@mui/icons-material'
import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'

export default function PersistentDrawerLeft(props) {
  const { data, defaultLang } = props
  const [open, setOpen] = useState(false)
  const drawerWidth = open ? 240 : 72
  const handleDrawerOpen = () => setOpen(true)
  const handleDrawerClose = () => setOpen(false)
  const navigationElements = [
    {
      title: 'Home',
      link: '/dashboard',
      icon: <HomeOutlined sx={{ color: BreakpointsHelper.isMobile() ? '#030b24' : 'white' }} />,
    },
    {
      title: 'Zamów',
      link: '/order',
      icon: <ShoppingCartOutlined sx={{ color: BreakpointsHelper.isMobile() ? '#030b24' : 'white' }} />,
    },
    {
      title: 'UNOkody',
      link: '/codes',
      icon: <NewspaperOutlined sx={{ color: BreakpointsHelper.isMobile() ? '#030b24' : 'white' }} />,
    },
    {
      title: 'Kontakt',
      link: '/contact',
      icon: <LocationOnOutlined sx={{ color: BreakpointsHelper.isMobile() ? '#030b24' : 'white' }} />,
    },
  ]
  return (
    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
      <PrimarySearchAppBar
        navigationElements={navigationElements}
        handleDrawerOpen={() => handleDrawerOpen()}
        handleDrawerClose={() => handleDrawerClose()}
        open={open}
        {...props}
      />
      {
        BreakpointsHelper.isMobile()
        ? (
          <Navigation
            navigationElements={navigationElements}
            location={location}
            open={true}
            setOpen={setOpen}
            handleDrawerClose={() => handleDrawerClose()}
            defaultLang={defaultLang}
            minVersion
          />
        )
        : (
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
                visibility: 'visible !important',
                transform: 'translateX(0) !important',
                overflow: 'hidden',
              },
            }}
            variant="persistent"
            anchor="left"
            open={open}
            onClose={() => handleDrawerClose()}
          >
            <Navigation
              navigationElements={navigationElements}
              location={location}
              open={open}
              setOpen={setOpen}
              handleDrawerClose={() => handleDrawerClose()}
              defaultLang={defaultLang}
            />
          </Drawer>
        )
      }
      <Box
        sx={{
          marginLeft: BreakpointsHelper.isMobile() ? '0' : open ? '0' : `-${drawerWidth}px`,
          width: BreakpointsHelper.isMobile() ? '100%' : open ? 'calc(100% - 240px)' : 'calc(100% - 72px)',
        }}
      >
        {data()}
      </Box>
    </Box>
  )
}
