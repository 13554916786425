import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

const PolicyPage = () => {
  const style = {
    root: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      padding: '100px',
      minHeight: 'calc(100vh - 120px)'
    },
    title: {
      fontSize: '28px',
      textAlign: 'center',
      width: '100%',
      fontWeight: 'bold',
      marginBottom: '50px',
    },
    text: {
      fontSize: '18px',
      marginBottom: '20px',
    }
  }
  return (
    <Box sx={style.root}>
      <Typography sx={style.title}>Polityka prywatności</Typography>
      <Typography sx={style.text}>
        Niniejsza Polityka prywatności wyjaśnia, w jaki sposób korzystamy z informacji, jak je
        gromadzimy i przechowujemy. Korzystając z niniejszej strony internetowej pod adresem:
        www.unokoszalin.pl oraz aplikacji mobilnej pod adresem: aplikacja.unokoszalin.pl (strona i
        aplikacja mobilna prezentująca przedsiębiorcę Krowa Burger Sp. z o.o. z siedzibą w
        Koszalinie) wyrażają Państwo zgodę na zasady opisane w niniejszej Polityce prywatności.
        Gromadzimy dane osobowe z największą starannością i zgodnie z obowiązującymi przepisami
        prawnymi, przestrzegając prawa do ochrony prywatności i gwarantujemy wszystkim osobom
        korzystającym ze strony firmy, że udostępnione dane będą właściwie chronione przed
        przejęciem przez osoby trzecie.
      </Typography>
      <Typography sx={style.text}>
        Kto jest administratorem danych osobowych przekazywanych w trakcie korzystania ze
        strony internetowej pod adresem: www.unokoszalin.pl oraz aplikacji mobilnej pod
        adresem: aplikacja.unokoszalin.pl
      </Typography>
      <Typography sx={style.text}>
        Administratorami danych osobowych jest Krowa Burger Sp. z o.o. w Koszalinie, przy ul.
        Piastowskiej 21, 75-400 Koszalin, wpisaną do rejestru przedsiębiorców prowadzonego przez
        Sąd Rejonowy w Koszalinie, IX Wydział Gospodarczy Krajowego Rejestru Sądowego pod
        numerem KRS: 0000890502, posiadającą numer NIP: 6692560898 oraz numer Regon:
        38846208500000.
      </Typography>
      <Typography sx={style.text}>
        Jak działa strony internetowej pod adresem: www.unokoszalin.pl oraz aplikacji
        mobilnej pod adresem: aplikacja.unokoszalin.pl?
      </Typography>
      <Typography sx={style.text}>
        Korzystanie ze strony internetowej oraz aplikacji mobilnej polega na ich przeglądaniu celem
        zapoznania się z przedmiotem działalności firmy Krowa Burger Sp. z o.o. w Koszalinie,
        (oferowanymi usługami) oraz pozostawieniu danych osobowych (imienia, nazwiska, adresu e
        – mail, telefonu) w celu otrzymania odpowiedzi na zadane za pośrednictwem formularza
        kontaktowego pytania lub uzyskaniu informacji o ofercie Ubic Sp. z o.o. z siedzibą Warszawie
      </Typography>
      <Typography sx={style.text}>
        Jakie dane gromadzimy?
      </Typography>
      <Typography sx={style.text}>
        Dane gromadzone za pomocą formularza to: imię, nazwisko, email lub telefon. Podanie
        powyższych danych jest dobrowolne, lecz konieczne w celu kontaktu zwrotnego oraz
        przedstawienia oferty usług, które Państwa interesują. Możemy również rejestrować dane,
        kiedy kontaktujecie się Państwo z nami telefonicznie lub za pośrednictwem poczty
        elektronicznej. Serwis korzysta również z plików cookies. Aby dowiedzieć się więcej proszę
        przejść do naszej Polityki cookies, która znajduje się poniżej.
      </Typography>
      <Typography sx={style.text}>
        Jak korzystamy z Twoich danych?
      </Typography>
      <Typography sx={style.text}>
        Przekazane dane osobowe wykorzystujemy tylko w celu nawiązania kontaktu z osobami
        zainteresowanymi (wysyłającymi zapytania za pomocą formularza kontaktowego lub
        bezpośrednio na adresy e – mail wskazane na stronie) lub celem przedstawienia oferty usług
        firmy w związku ze złożonym zapytaniem. Przekazanie przez Państwa danych osobowych za
        pośrednictwem formularza kontaktowego lub bezpośrednio na wskazane na stronie adresy e –
        mail jest przez nas traktowane jednoznacznie jako wyrażenie zgody na przetwarzanie danych
        osobowych w celach wskazanych powyżej.
      </Typography>
      <Typography sx={style.text}>
        Jakie są twoje prawa w odniesieniu do gromadzonych przez nas danych osobowych?
      </Typography>
      <Typography sx={style.text}>
        Dane zebrane w sposób opisany w niniejszej Polityce prywatności przechowujemy i
        przetwarzamy w sposób zgodny z wymogami prawa. Przysługuje Ci prawo dostępu do treści
        Twoich danych, możliwość ich poprawiania, jak również do cofnięcia zgody w każdym czasie.
        W tym celu powinieneś skontaktować się z nami.
      </Typography>
      <Typography sx={style.text}>
        Bezpieczeństwo i poufność
      </Typography>
      <Typography sx={style.text}>
        Wykorzystujemy wszelkie techniczne i organizacyjne sposoby, by zapewnić bezpieczeństwo
        przekazanych danych osobowych i chronić je przed przypadkowym lub celowym
        zniszczeniem, przypadkową utratą, modyfikacją, nieautoryzowanym ujawnieniem lub
        dostępem. Informacje są przechowywane i przetwarzane na serwerach o wysokim stopniu
        zabezpieczeń, z zachowaniem odpowiednich środków bezpieczeństwa, spełniających wymogi
        polskiego prawa.
      </Typography>
      <Typography sx={style.text}>
        Prawa autorskie
      </Typography>
      <Typography sx={style.text}>
        Strona internetowa oraz aplikacja mobilna jak również treści na nich zamieszczone stanowią
        wyłączną własnością Krowa Burger Sp. z o.o. w Koszalinie,
      </Typography>
      <Typography sx={style.text}>
        Zapraszając odwiedzających do korzystania ze strony internetowej pod adresem:
        www.unokoszalin.pl oraz aplikacji mobilnej pod adresem: aplikacja.unokoszalin.pl, zwracamy
        uwagę na wymóg poszanowania praw własności intelektualnej. Strona zawiera chronione
        prawem autorskim treści, znaki towarowe oraz inne materiały, w szczególności teksty, grafikę,
        rysunki, animacje, dźwięki i programy. Układ oraz wybór prezentowanych treści stanowi
        samoistny przedmiot ochrony prawa autorskiego. Kopiowanie, przesyłanie, publiczne
        odtwarzanie oraz wszelkie wykorzystywanie treści prezentowanych na stronie internetowej
        do celów innych niż osobisty użytek (związany z osobistą działalnością) wymaga uprzedniej,
        pisemnej zgody partnerów firmy.
      </Typography>
      <Typography sx={style.text}>
        Postanowienia końcowe
      </Typography>
      <Typography sx={style.text}>
        Poprzez przystąpienie do korzystania ze strony internetowej, każda korzystająca z niej osoba
        wyraża zgodę na warunki określone w niniejszej Polityce prywatności.
      </Typography>
      <Typography sx={style.text}>
        Nasze dane kontaktowe
      </Typography>
      <Typography sx={style.text}>
        Wszystkie niezbędne dane kontaktowe znajdują się na stronie wecandoit.social w zakładce
        KONTAKT. W kwestiach dotyczących przetwarzania danych osobowych proszę o kontakt na
        adres mailowy koszalinuno@gmail.com
      </Typography>
      <Typography sx={style.text}>
        Polityka plików Cookies
      </Typography>
      <Typography sx={style.text}>
        Niniejsza Polityka dotyczy plików „cookies” i odnosi się do strony internetowej pod adresem
        wecandoit.social.
      </Typography>
      <Typography sx={style.text}>
        Czym są pliki „cookies”?
      </Typography>
      <Typography sx={style.text}>
        Poprzez pliki „cookies” należy rozumieć dane informatyczne, w szczególności pliki tekstowe,
        przechowywane w urządzeniach końcowych użytkowników przeznaczone do korzystania ze
        stron internetowych. Pliki te pozwalają rozpoznać urządzenie użytkownika i odpowiednio
        wyświetlić stronę internetową dostosowaną do jego indywidualnych preferencji. „Cookies”
        zazwyczaj zawierają nazwę strony internetowej z której pochodzą, czas przechowywania ich
        na urządzeniu końcowym oraz unikalny numer.
      </Typography>
      <Typography sx={style.text}>
        Do czego używamy plików „cookies”?
      </Typography>
      <Typography sx={style.text}>
        Pliki „cookies” używane są w celu dostosowania zawartości stron internetowych do preferencji
        użytkownika oraz optymalizacji korzystania ze stron internetowych. Używane są również w
        celu tworzenia anonimowych statystyk, które pomagają zrozumieć w jaki sposób użytkownik
        korzysta ze strony internetowych co umożliwia ulepszanie jej struktury i zawartości, z
        wyłączeniem personalnej identyfikacji użytkownika.
      </Typography>
      <Typography sx={style.text}>
        Jakich plików „cookies” używamy?
      </Typography>
      <Typography sx={style.text}>
        Stosowane są, co do zasady, dwa rodzaje plików „cookies” – „sesyjne” oraz „stałe”.
      </Typography>
      <Typography sx={style.text}>
        Pierwsze z nich są plikami tymczasowymi, które pozostają na urządzeniu użytkownika, aż do
        wylogowania ze strony internetowej lub wyłączenia oprogramowania (przeglądarki
        internetowej). „Stałe” pliki pozostają na urządzeniu użytkownika przez czas określony w
        parametrach plików „cookies” albo do momentu ich ręcznego usunięcia przez użytkownika.
        Pliki „cookies” wykorzystywane przez partnerów operatora strony internetowej, w tym w
        szczególności użytkowników strony internetowej, podlegają ich własnej polityce prywatności.
      </Typography>
      <Typography sx={style.text}>
        Wyróżnić można szczegółowy podział cookies, ze względu na:
        A. RODZAJE COOKIES ZE WZGLĘDU NA NIEZBĘDNOŚĆ DO REALIZACJI USŁUGI
        Niezbędne są absolutnie niezbędne do prawidłowego funkcjonowania witryny lub
        funkcjonalności z których użytkownik chce skorzystać.
        Funkcjonalne są ważne dla działania serwisu: służą wzbogaceniu funkcjonalności
        serwisu, bez nich serwis będzie działał poprawnie, jednak nie będzie dostosowany do
        preferencji użytkownika, służą zapewnieniu wysokiego poziomu funkcjonalności
        serwisu, bez ustawień zapisanych w pliku cookie może obniżyć się poziom
        funkcjonalności witryny, ale nie powinna uniemożliwić zupełnego korzystania z niej,
        służą bardzo ważnym funkcjonalnościom serwisu, ich zablokowanie spowoduje, że
        wybrane funkcje nie będą działać prawidłowo.
        Biznesowe umożliwiają realizację modelu biznesowego, w oparciu o który
        udostępniona jest witryna, ich zablokowanie nie spowoduje niedostępności całości
        funkcjonalności serwisu ale może obniżyć poziom świadczenia usługi ze względu na
        brak możliwości realizacji przez właściciela witryny przychodów subsydiujących
        działanie serwisu. Do tej kategorii należą np. cookies reklamowe.
        B. ZE WZGLĘDU NA CZAS PRZEZ JAKI COOKIE BĘDZIE UMIESZCZONE W URZĄDZENIU
        KOŃCOWYM UŻYTKOWNIKA:
        Cookies tymczasowe (session cookies) cookie umieszczone na czas korzystania z
        przeglądarki (sesji), zostaje wykasowane po jej zamknięciu,
        Cookies stałe (persistent cookie) nie jest kasowane po zamknięciu przeglądarki i
        pozostaje
        w urządzeniu użytkownika na określony czas lub bez okresu ważności w zależności od
        ustawień właściciela witryny.
        ZE WZGLĘDU NA POCHODZENIE – ADMINISTRATORA SERWISU, KTÓRY ZARZĄDZA
        COOKIES:
        Cookie własne (first party cookie) cookie umieszczone bezpośrednio przez właściciela
        witryny jaka została odwiedzona
        Cookie zewnętrzne (third-party cookie) cookie umieszczone przez zewnętrzne
        podmioty, których komponenty stron zostały wywołane przez właściciela witryny.
        Uwaga: cookie mogą być wywołane przez administratora za pomocą skryptów,
        komponentów, które znajdują się na serwerach partnera, umiejscowionych w innej
        lokalizacji – innym kraju lub nawet zupełnie innym systemie prawnym. W przypadku
        wywołania przez administratora witryny komponentów serwisu pochodzących spoza
        systemu administratora mogą obowiązywać inne standardowe zasady polityki cookies
        niż polityka prywatności / cookies administratora witryny.
        D. ZE WZGLĘDU NA CEL JAKIEMU SŁUŻĄ:
        Umożliwiają ustawienia funkcji i usług w serwisie.
        Umożliwiają weryfikację autentyczności oraz optymalizację wydajności serwisu.
        Umożliwiają informowanie gdy użytkownik jest zalogowany, dzięki czemu witryna
        może pokazywać odpowiednie informacje i funkcje.
        Umożliwiają zapisywanie informacji o tym, jak użytkownicy korzystają z witryny.
        Mogą one dotyczyć najczęściej odwiedzanych stron lub ewentualnych komunikatów o
        błędach wyświetlanych na niektórych stronach. Pliki cookie służące do zapisywania
        tzw. „stanu sesji” pomagają ulepszać usługi i zwiększać komfort przeglądania stron.
        Umożliwiają sprawne działanie samej witryny oraz dostępnych na niej funkcji.
        Umożliwiają wyświetlać reklamy, które są bardziej interesujące dla użytkowników, a
        jednocześnie bardziej wartościowe dla wydawców i reklamodawców, personalizować
        reklamy, mogą być używane również do wyświetlania reklam poza stronami witryny
        (domeny).
        Umożliwiają dostosowanie wyświetlanych informacji do lokalizacji użytkownika.
        Umożliwiają właścicielom witryn lepiej zrozumieć preferencje ich użytkowników i
        poprzez
        analizę ulepszać i rozwijać produkty i usługi. Zazwyczaj właściciel witryny lub firma
        badawcza
        zbiera anonimowo informacje i przetwarza dane na temat trendów bez
        identyfikowania danych
        osobowych poszczególnych użytkowników.
        E. RODZAJE COOKIES ZE WZGLĘDU NA INGERENCJĘ W PRYWATNOŚĆ
        UŻYTKOWNIKA:
        Nieszkodliwe
        Obejmuje Cookies niezbędne do poprawnego działania witryny, potrzebne do
        umożliwienia działania funkcjonalności witryny, jednak ich działanie nie ma nic
        wspólnego z śledzeniem użytkownika.
        Badające wykorzystywane do śledzenia użytkowników jednak nie obejmują
        informacji pozwalających zidentyfikować danych konkretnego użytkownika.
      </Typography>
      <Typography sx={style.text}>
        Czy pliki „cookies” zawierają dane osobowe
      </Typography>
      <Typography sx={style.text}>
        Dane osobowe gromadzone przy użyciu plików „cookies” mogą być zbierane wyłącznie
        w celu wykonywania określonych funkcji na rzecz użytkownika. Takie dane są
        zaszyfrowane w sposób uniemożliwiający dostęp do nich osobom nieuprawnionym.
      </Typography>
      <Typography sx={style.text}>
        Usuwanie plików „cookies”
      </Typography>
      <Typography sx={style.text}>
        Standardowo oprogramowanie służące do przeglądania stron internetowych
        domyślnie dopuszcza umieszczanie plików „cookies” na urządzeniu końcowym.
        Ustawienia te mogą zostać zmienione w taki sposób, aby blokować automatyczną
        obsługę plików „cookies” w ustawieniach przeglądarki internetowej bądź informować
        o nich każdorazowym przesłaniu na urządzenie użytkownika.
      </Typography>
      <Typography sx={style.text}>
        Szczegółowe informacje o możliwości i sposobach obsługi plików „cookies” dostępne są
        w ustawieniach oprogramowania (przeglądarki internetowej).Ograniczenie stosowania
        plików „cookies”, może wpłynąć na niektóre funkcjonalności dostępne na stronie
        internetowej.
      </Typography>
    </Box>
  )
}

export default PolicyPage
