import { Grid } from '@mui/material'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import Tabs from '../../components/molecules/Tabs/Tabs'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { deleteAccount } from '../../firebase'

const DeletePage = () => {
  const style = {
    root: {
      display: 'flex',
      marginTop: 10,
      padding: BreakpointsHelper.isMobile() ? '15px' : '15px 50px',
    },
    loader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '500px',
    },
  }
  const dataTabs = [
    {
      name: 'Usuwanie konta',
      value: () => (
        <Box>
          <Button variant="contained" onClick={() => history.open('/profile')}>Cofnij do profilu</Button>
          <Grid container>
            <Grid item md={12}>
              <Typography sx={{ marginTop: '20px', fontSize: '25px', fontWeight: '600', textTransform: 'uppercase' }}>Reguły usunięcia konta</Typography>
              <Typography sx={{ marginTop: '20px', fontSize: '22px', fontWeight: '400' }}>• usunięcie konta całkowicie po wybraniu przycisku</Typography>
              <Typography sx={{ marginTop: '20px', fontSize: '22px', fontWeight: '400' }}>• dane z profilu nie są do odzyskania</Typography>
              <Typography sx={{ marginTop: '20px', fontSize: '22px', fontWeight: '400' }}>• wszystkie dane powiązane z kontem również zostają usunięte</Typography>
              <Typography sx={{ marginTop: '20px', fontSize: '22px', fontWeight: '400' }}>• usunięcie jest nieodwracalne</Typography>
              <Button variant="contained" onClick={() => deleteAccount()} sx={{ marginTop: '20px', background: 'red', color: 'white' }}>Czy na pewno chcesz usunąć konto?</Button>
            </Grid>
          </Grid>
        </Box>
      ),
    },
  ]
  return (
    <>
      <div style={style.root}>
        <Tabs data={dataTabs}/>
      </div>
    </>
  )
}

export default DeletePage
