import { Button, Grid, Typography } from '@mui/material'
import LocalStorageHelper from '../../helpers/LocalStorageHelper/LocalStorageHelper'
import FormGenerator from '../../components/organisms/FormGenerator/FormGenerator'
import UserHelper from '../../helpers/UserHelper/UserHelper'
import { useSnackbar } from 'notistack'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import CookiesHelper from '../../helpers/CookiesHelper/CookiesHelper'
import Box from '@mui/material/Box'
import { CheckOutlined } from '@mui/icons-material'
import { Helmet } from 'react-helmet'

const LoginPage = (props) => {
  const t = LocalStorageHelper.get('translation')
  const { enqueueSnackbar } = useSnackbar()
  const formData = {
    elements: [
      {
        name: 'email',
        type: 'email',
        label: t.globals.inputs.email.label,
        value: CookiesHelper.get('saveEmail') || '',
        validationType: 'string',
      },
      {
        name: 'password',
        type: 'password',
        label: t.globals.inputs.password.label,
        value: CookiesHelper.get('savePassword') || '',
        helperText: t.globals.inputs.password.helperText,
        validationType: 'string',
      },
      {
        type: 'button',
        value: t.loginPage.buttons.login,
      }
    ],
  }
  const style = {
    root: {
      padding: BreakpointsHelper.isMobile() ? '15px' : '50px',
      fontSize: BreakpointsHelper.isMobile() ? '24px' : '25px',
      fontWeight: 700,
      maxWidth: BreakpointsHelper.isMobile() ? 'none' : '450px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
	    textAlign: 'center',
    },
    desc: {
      maxWidth: '320px',
      fontSize: BreakpointsHelper.isMobile() ? '16px' : '18px',
      fontWeight: BreakpointsHelper.isMobile() ? '400' : '300',
      textTransform: 'none',
      textAlign: 'center',
    },
    icon: {
      marginRight: '5px',
    },
    threeElements: {
      background: '#00CAE6',
      padding: BreakpointsHelper.isMobile() ? '3px' : '5px',
      borderRadius: '50%',
      width: BreakpointsHelper.isMobile() ? '10px' : '20px',
      height: BreakpointsHelper.isMobile() ? '10px' : '20px',
      marginRight: '10px',
    },
  }
  const createData = async (res) => {
    const data = {
      accessToken: res?.res?.accessToken,
      uid: res?.res?.uid,
      email: res?.res?.email,
      photoURL: res?.res?.photoURL,
      role: res?.database?.role || 'user',
      phone: res?.database?.phone,
      name: res?.database?.name,
      apartment: res?.database?.apartment,
      street: res?.database?.street,
      house: res?.database?.house,
      defaultLang: res?.database?.defaultLang,
    }
    LocalStorageHelper.set('user', data)
    return data
  }
  const handleLogin = (e) => {
    UserHelper.login(e).then(res => {
      if (res) {
	      if (!CookiesHelper.get('saveEmail') && !CookiesHelper.get('savePassword')) {
		      CookiesHelper.set('saveEmail', e.email)
		      CookiesHelper.set('savePassword', e.password)
	      }
	      if (res?.res?.emailVerified) {
          createData(res).then(res => {
            if (res) props.location.history.push('/dashboard')
          })
        }
        if (!res?.res?.emailVerified) enqueueSnackbar(t.loginPage.toasts.notActive, { variant: 'warning' })
      } else enqueueSnackbar(t.loginPage.toasts.error, { variant: 'error' })
    }).catch(() => {
      enqueueSnackbar(t.loginPage.toasts.wrongData, { variant: 'error' })
    })
  }
  return (
		<div style={style.root}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Pizzeria UNO Koszalin - Katastrofa na waszej planecie okazała się pięknym wydarzeniem. Pokażemy wam Kosmicznie Dobrą Pizzę</title>
        <meta name="keywords" content="pizzeria, uno, koszalin"/>
        <meta name="description" content="Katastrofa na waszej planecie okazała się pięknym wydarzeniem. Pokażemy wam Kosmicznie Dobrą Pizzę" />
        <meta name="subject" content="Planszówki Koszalin | Klub planszówkowy | Koszalin"/>
        <meta name="copyright" content="Pizzeria UNO Koszalin"/>
        <meta name="language" content="PL"/>
        <meta name="robots" content="index,follow" />
        <meta name="author" content="Jan Szymański, kontakt@your-site.pl"/>
        <meta name="designer" content="Jan Szymański, kontakt@your-site.pl"/>
        <meta name="copyright" content="Jan Szymański, kontakt@your-site.pl"/>
        <meta name="owner" content="Jan Szymański, kontakt@your-site.pl"/>
        <meta name="url" content="https://unokoszalin.pl/"/>
        <meta name="identifier-URL" content="https://unokoszalin.pl/"/>
        <meta name="category" content="Pizzeria"/>
        <meta name="distribution" content="Global"/>
        <meta name="rating" content="General"/>
        <meta name="revisit-after" content="7 days"/>
        <meta httpEquiv="Expires" content="0"/>
        <meta httpEquiv="Pragma" content="no-cache"/>
        <meta httpEquiv="Cache-Control" content="no-cache"/>
        <meta name="og:title" content="Pizzeria UNO Koszalin - Katastrofa na waszej planecie okazała się pięknym wydarzeniem. Pokażemy wam Kosmicznie Dobrą Pizzę"/>
        <meta name="og:type" content="website"/>
        <meta name="og:url" content="https://planszowki-koszalin.pl"/>
        <meta name="og:image" content="http://localhost:3000/static/media/website1.9f1b6babd1b88a4cad9e.jpg"/>
        <meta name="og:site_name" content="Pizzeria UNO Koszalin"/>
        <meta name="og:description" content="Katastrofa na waszej planecie okazała się pięknym wydarzeniem. Pokażemy wam Kosmicznie Dobrą Pizzę"/>
        <meta name="fb:page_id" content="unokoszalin" />
        <meta name="og:email" content="koszalinuno@gmail.com"/>
        <meta name="og:phone_number" content="576283201"/>
        <meta name="og:locality" content="Koszalin"/>
        <meta name="og:region" content="Zachodniopomorskie"/>
        <meta name="og:postal-code" content="75-063"/>
        <meta name="og:country-name" content="Polska"/>
        <meta property="og:type" content="website"/>
        <meta name="apple-mobile-web-app-capable" content="yes"/>
        <meta content="yes" name="apple-touch-fullscreen" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black"/>
        <meta name="format-detection" content="telephone=no"/>
      </Helmet>
      <Typography sx={{ marginBottom: '20px', marginTop: '20px', fontWeight: '800', fontSize: '29px', color: 'white' }}>
        Zaloguj się
      </Typography>
      <Grid container spacing={2}>
        <Grid item md={4} xs={4}>
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '30px' }}>
            <CheckOutlined sx={style.threeElements} />
            <Typography sx={{ fontSize: BreakpointsHelper.isMobile() ? '12px' : '14px', color: 'white' }}>
              Nowości
            </Typography>
          </Box>
        </Grid>
        <Grid item md={4} xs={4}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CheckOutlined sx={style.threeElements} />
            <Typography sx={{ fontSize: BreakpointsHelper.isMobile() ? '12px' : '14px', color: 'white' }}>
              UNOkody
            </Typography>
          </Box>
        </Grid>
        <Grid item md={4} xs={4}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CheckOutlined sx={style.threeElements} />
            <Typography sx={{ fontSize: BreakpointsHelper.isMobile() ? '12px' : '14px', color: 'white' }}>
              Wydarzenia
            </Typography>
          </Box>
        </Grid>
      </Grid>
      {/* <Button */}
      {/*  sx={{ position: 'absolute', top: '20px', left: '20px', background: '#00CAE6', fontWeight: '600', color: 'white' }} */}
      {/*  variant="contained" */}
      {/*  onClick={() => props.location.history.push('/home')} */}
      {/* > */}
      {/*  {t.loginPage.buttons.goHome} */}
      {/* </Button> */}
			<FormGenerator data={formData} submit={(e) => handleLogin(e)} {...props}/>
      <Button
          sx={{ marginBottom: '10px', color: '#00CAE6', fontWeight: '600' }}
          fullWidth
          onClick={() => props?.location?.history?.push('/register')}
      >
        {t.loginPage.buttons.register}
      </Button>
			<Typography sx={{ marginBottom: '20px', marginTop: '20px', textTransform: 'lowercase', fontWeight: '700' }}>
        {t.loginPage.buttons.remember}
			</Typography>
			<Button
				sx={{ marginBottom: '10px', background: '#00CAE6', fontWeight: '600', color: 'white' }}
				fullWidth
				variant="contained"
				onClick={() => props?.location?.history?.push('/reset')}
			>
        {t.loginPage.buttons.reset}
			</Button>
      <Box
        sx={{
          padding: '20px',
          display: 'flex',
          minHeight: '400px',
          position: 'relative',
          zIndex: '2',
          background: 'rgb(3, 11, 36)',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: BreakpointsHelper.isMobile() ? 'flex-start' : 'center',
            justifyContent: 'center',
            flexDirection: BreakpointsHelper.isMobile() && 'column',
            height: '80px',
          }}
        >
          <Typography
            sx={{
              marginLeft: '10px',
              cursor: 'pointer',
            }}
          >
            <a href="/regulation" rel="noreferrer">
              Regulamin serwisu
            </a>
          </Typography>
          <Typography
            sx={{
              marginLeft: '10px',
              cursor: 'pointer',
            }}
          >
            <a href="/policy" rel="noreferrer">
              Polityka prywatności
            </a>
          </Typography>
        </Box>
      </Box>
		</div>
  )
}

export default LoginPage
