import React from 'react'
import PropTypes from 'prop-types'
import './SimpleLayout.scss'
import ChangeLang from '../../components/organisms/ChangeLang/ChangeLang'

const SimpleLayout = (props) => {
  const Component = props.component
  const { location } = props
  const style = {
    root: {
      display: 'flex',
      margin: '0 auto',
      overflow: 'hidden',
      height: '100vh',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      background: '#030b24',
      width: '100%',
    },
  }
  return (
		<div style={style.root}>
      <ChangeLang outSide noMargin />
      <Component location={location} />
    </div>
  )
}

SimpleLayout.defaultProps = {
  location: false,
}

SimpleLayout.propsType = {
  location: PropTypes.object,
}

export default SimpleLayout
