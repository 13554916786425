import { useEffect } from 'react'
import PropTypes from 'prop-types'
import './MainLayout.scss'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import { refreshToken } from '../../firebase'
import LocalStorageHelper from '../../helpers/LocalStorageHelper/LocalStorageHelper'
import LangHelper from '../../helpers/LangHelper/LangHelper'
import Drawer from '../../components/atoms/Drawer/Drawer'

const MainLayout = (props) => {
  const Component = props.component
  const { location } = props
  const defaultLang = LocalStorageHelper.get('user')?.defaultLang
  const isOrder = window.location.pathname.replace('/', '') === 'order'
  const style = {
    root: {
      display: 'flex',
      overflow: 'hidden',
    },
    main: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      width: BreakpointsHelper.isMobile() ? 'calc(100% - 40px)' : 'calc(100% - 60px)a',
      marginTop: BreakpointsHelper.isMobile() ? '57px' : '65px',
      minHeight: BreakpointsHelper.isMobile() ? 'calc(100vh - 114px)' : 'calc(100vh - 124px)',
      background: 'rgb(3, 11, 36)',
      color: 'white',
      padding: BreakpointsHelper.isMobile() ? isOrder ? '20px 20px 100px 20px' : '20px' : '30px',
    },
  }
  const createComponent = () => {
    return (
      <div style={style.main}><Component location={location} /></div>
    )
  }
  useEffect(() => {
    refreshToken()
    if (defaultLang) {
      LangHelper.changeLang(defaultLang)
    }
  }, [])
  return (
    <div style={style.root}>
      <Drawer location={location} defaultLang={defaultLang} data={createComponent} {...props} />
    </div>
  )
}

MainLayout.defaultProps = {
  location: false,
}

MainLayout.propsType = {
  location: PropTypes.object,
}

export default MainLayout
