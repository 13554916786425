import { Grid } from '@mui/material'
import Typography from '@mui/material/Typography'
import { useEffect, useState } from 'react'
import { getAllNews } from '../../firebase'
import MagicLoader from '../../components/atoms/MagicLoader/MagicLoader'

const NewsPage = () => {
  const [news, setNews] = useState([])
  const style = {
    img: {
      width: '100%',
    },
  }
  const handleNews = async () => {
    return await getAllNews().then(async res => {
      if (res) {
        const tempData = []
        await res?.map(el => {
          const data = el?.data
          if (data) {
            tempData.push(data)
          }
          return true
        })
        tempData.reverse()
        if (tempData?.length !== news?.length) {
          setNews(tempData)
        }
      }
    })
  }
  useEffect(() => {
    handleNews()
  }, [])
  return (
    <>
      <Typography sx={{ marginBottom: '47px', fontWeight: '800', fontSize: '22px' }}>Aktualności</Typography>
      <MagicLoader time={1500} variable={news}>
        <Grid container spacing={4}>
           {
            news?.map((el, index) => (
              <Grid key={index} item md={3} xs={12}>
                <a href={el?.url} target="_blank" rel="noreferrer">
                  <img src={el?.urlImage} alt={el?.name} style={style.img} className="news" />
                </a>
              </Grid>
            ))
           }
        </Grid>
      </MagicLoader>
    </>
  )
}

export default NewsPage
