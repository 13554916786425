import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { useState } from 'react'
import Dialog from '../Dialog/Dialog'
import QRCode from 'react-qr-code'
import LocalStorageHelper from '../../../helpers/LocalStorageHelper/LocalStorageHelper'

const Code = (props) => {
  const { data } = props
  const used = data?.used?.length > 0 ? !!data?.used?.find(el => el === LocalStorageHelper.get('user')?.email) : false
  const [open, setOpen] = useState(false)
  const style = {
    root: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      padding: '20px',
      background: used ? '#BCBEC0' : '#FFBC00',
      borderRadius: '4px',
      flexDirection: 'column',
      height: '150px',
    },
    title: {
      color: used ? '#030B2480' : '#030B24',
      fontWeight: '800',
      fontSize: '16px',
      lineHeight: '29px',
    },
    titleSec: {
      color: used ? '#030B2480' : '#030B2480',
      fontWeight: '600',
      fontSize: '14px',
      lineHeight: '20px',
    },
    button: {
      background: used ? 'white' : '#00CAE6',
      color: '#030B2480',
      fontWeight: '800',
      textTransform: 'uppercase',
      width: '100%',
      marginTop: '31px',
      cursor: used ? 'auto' : 'pointer',
      '&:hover': {
        color: 'white',
      }
    },
    buttonUsed: {
      background: used ? 'white' : '#00CAE6',
      color: used ? '#030B2480' : 'white',
      fontWeight: '800',
      padding: '5px 10px',
      textTransform: 'uppercase',
      borderRadius: '4px',
      textAlign: 'center',
      width: 'calc(100% - 20px)',
      marginTop: '31px',
      cursor: used ? 'auto' : 'pointer',
    }
  }
  const handleCode = () => {
    setOpen(true)
  }
  const generateQRCode = () => {
    return (
      <div>
        <QRCode value={data?.id + '/' + LocalStorageHelper.get('user').email} />
      </div>
    )
  }
  return (
    <Box sx={style.root} className="Code">
      <Typography sx={style.title}>
        {data.name}
      </Typography>
      <Typography sx={style.titleSec}>
        {data.place === 'here' ? 'Na miejscu' : 'Na wynos'}
      </Typography>
      {
        used
? (
          <Box sx={style.buttonUsed}>
              WYKORZYSTANY
          </Box>
        )
: (
          <Button
            onClick={() => handleCode()}
            sx={style.button}
          >
            ODBIERZ UNOKOD
          </Button>
        )
      }
      { open && !used && <Dialog open={open} title="Podgląd uno kodów" content={generateQRCode()} onClose={() => setOpen(false)} /> }
    </Box>
  )
}

export default Code
