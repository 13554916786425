import { Grid } from '@mui/material'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

const Pizza = (props) => {
  const { image, name, ingredients, price = 0, payWith = { currency: 'pln', value: 'zł' } } = props
  const style = {
    root: {
      border: '1px solid #f3f3f3',
      padding: '30px',
      background: '#030b24',
      color: 'white',
      cursor: 'pointer',
    },
    image: {
      width: '80%',
      textAlign: 'center',
    },
    box: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'space-between',
      flexDirection: 'column',
      height: '100%',
    },
    title: {
      fontSize: '32px',
      fontWeight: '600',
    },
    desc: {
      fontSize: '22px',
      fontWeight: '400',
    },
    price: {
      fontSize: '32px',
      fontWeight: '600',
      color: '#00e0ff',
      marginTop: '20px',
    },
  }
  return (
    <Box style={style.root}>
      <Grid container spacing={4}>
        <Grid item md={6} xs={12}>
          <img src={image} alt={name} style={style.image} />
        </Grid>
        <Grid item md={6} xs={12}>
          <Box sx={style.box}>
            <Typography style={style.title}>{name}</Typography>
            <Typography style={style.desc}>{ingredients}</Typography>
            <Typography style={style.price}>{price.toFixed(2)} {payWith.value}</Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Pizza
