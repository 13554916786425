import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import AccountCircle from '@mui/icons-material/AccountCircle'
import { useEffect, useState } from 'react'
import UserHelper from '../../../helpers/UserHelper/UserHelper'
import LocalStorageHelper from '../../../helpers/LocalStorageHelper/LocalStorageHelper'
import { useHistory } from 'react-router'
import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'
import Logo from '../../../assets/images/logo.svg'

const PrimarySearchAppBar = (props) => {
  const { open, handleDrawerOpen, handleDrawerClose, navigationElements } = props
  const history = useHistory()
  const pathName = history.location.pathname
  const [anchorEl, setAnchorEl] = useState(open)
  const [namePathName, setNamePathName] = useState(open)
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null)
  const isMenuOpen = Boolean(anchorEl)
  const isAdmin = (LocalStorageHelper.get('user').role === 'admin' || LocalStorageHelper.get('user').role === 'service')
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)
  const handleProfileMenuOpen = (event) => setAnchorEl(event.currentTarget)
  const handleMobileMenuClose = () => setMobileMoreAnchorEl(null)
  const handleMenuClose = () => {
    setAnchorEl(null)
    handleMobileMenuClose()
    return true
  }
  const style = {
    img: {
      height: '45px',
    },
  }
  const handlePathName = () => {
    let elementToReturn = ''
    navigationElements.map(el => {
      if (el.link === pathName) {
        elementToReturn = el
      }
      return true
    })
    if (pathName === '/admin') {
      setNamePathName('Panel')
    } else if (pathName === '/profile') {
      setNamePathName('Profil')
    } else {
      setNamePathName(elementToReturn?.title)
    }
  }
  const menuId = 'primary-search-account-menu'
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      { isAdmin && <MenuItem sx={{ color: '#1b0f23' }} onClick={() => props.location.history.push('/admin')}>Admin</MenuItem> }
      { isAdmin && <MenuItem sx={{ color: '#1b0f23' }} onClick={() => props.location.history.push('/tours')}>Tours</MenuItem> }
      <MenuItem sx={{ color: '#1b0f23' }} onClick={() => props.location.history.push('/profile')}>Profile</MenuItem>
      <MenuItem sx={{ color: '#1b0f23' }} onClick={() => UserHelper.logout()}>Logout</MenuItem>
    </Menu>
  )

  const mobileMenuId = 'primary-search-account-menu-mobile'
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      { isAdmin && <MenuItem sx={{ color: '#1b0f23' }} onClick={() => props.location.history.push('/admin')}>Admin</MenuItem> }
      { isAdmin && <MenuItem sx={{ color: '#1b0f23' }} onClick={() => props.location.history.push('/tours')}>Tours</MenuItem> }
      <MenuItem sx={{ color: '#1b0f23' }} onClick={() => props.location.history.push('/profile')}>Profile</MenuItem>
      <MenuItem sx={{ color: '#1b0f23' }} onClick={() => UserHelper.logout()}>Logout</MenuItem>
    </Menu>
  )
  useEffect(() => {
    handlePathName()
  }, [pathName])
  return (
    <Box sx={{ flexGrow: 1, position: 'fixed', width: BreakpointsHelper.isMobile() ? '100%' : open ? 'calc(100% - 240px)' : 'calc(100% - 72px)' }}>
      <AppBar sx={{ background: 'rgb(3, 11, 36)', color: 'white' }} position="static">
        <Toolbar sx={{ borderBottom: BreakpointsHelper.isMobile() ? '1px solid white' : 'none' }}>
          {
            !BreakpointsHelper.isMobile()
            ? (
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                onClick={() => open ? handleDrawerClose() : handleDrawerOpen()}
                aria-label="open drawer"
                sx={{ mr: 2 }}
              >
                <MenuIcon />
              </IconButton>
            )
            : <img src={Logo} alt="logo" style={style.img} />
          }
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { sm: 'block', fontWeight: '800' }, marginLeft: { xs: '20px' } }}
          >
            {namePathName}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { md: 'flex' } }}>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  )
}

export default PrimarySearchAppBar
