import { useEffect } from 'react'
import { Grid } from '@mui/material'
import LocalStorageHelper from '../../helpers/LocalStorageHelper/LocalStorageHelper'
import { changeDataUser } from '../../firebase'
import { useSnackbar } from 'notistack'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import Tabs from '../../components/molecules/Tabs/Tabs'
import FormGenerator from '../../components/organisms/FormGenerator/FormGenerator'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { useHistory } from 'react-router'

const ProfilePage = (props) => {
  const t = LocalStorageHelper.get('translation')
  const history = useHistory()
  const user = LocalStorageHelper.get('user')
  const { enqueueSnackbar } = useSnackbar()
  const style = {
    root: {
      display: 'flex',
      marginTop: 10,
      padding: BreakpointsHelper.isMobile() ? '15px' : '15px 50px',
    },
    loader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '500px',
    },
  }
  const formData = {
    elements: [
      {
        name: 'name',
        type: 'text',
        label: t.globals.inputs.name.label,
        disabled: true,
        value: user?.name || '',
        validationType: 'string',
      },
      {
        name: 'email',
        type: 'email',
        label: t.globals.inputs.email.label,
        disabled: true,
        value: user?.email || '',
        validationType: 'string',
      },
      {
        name: 'password',
        type: 'password',
        label: t.globals.inputs.password.label,
        helperText: t.globals.inputs.password.helperText,
        validationType: 'string',
      },
      {
        name: 'phone',
        type: 'phone',
        disabled: true,
        label: t.globals.inputs.phone.label,
        value: user?.phone || '',
        validationType: 'string',
      },
      {
        name: 'street',
        type: 'text',
        label: t.globals.inputs.street.label,
        value: user?.street || '',
        validationType: 'string',
        required: false,
      },
      {
        name: 'house',
        type: 'number',
        label: t.globals.inputs.house.label,
        value: user?.house || '',
        validationType: 'string',
        required: false,
      },
      {
        name: 'apartment',
        type: 'number',
        label: t.globals.inputs.apartment.label,
        validationType: 'string',
        value: user?.apartment || '',
        required: false,
      },
      {
        name: 'defaultLang',
        type: 'select',
        label: t.globals.inputs.defaultLang.label,
        value: user?.defaultLang || '',
        items: [{ value: 'pl', name: t.globals.components.ChangeLang.pl }, { value: 'en', name: t.globals.components.ChangeLang.en }, { value: 'ua', name: t.globals.components.ChangeLang.ua }],
        validationType: 'string',
        required: false,
      },
      {
        type: 'button',
        value: t.globals.save,
      }
    ],
  }
  const handleChangeDataSubmit = (e) => {
    const data = {
      name: e.name,
      phone: e.phone,
      street: e.street,
      apartment: e.apartment,
      house: e.house,
      defaultLang: e.defaultLang,
    }
    const email = e.email
    changeDataUser(email, data).then((res) => {
      if (res) enqueueSnackbar('Zmieniono dane poprawnie.', { variant: 'success' })
      else enqueueSnackbar('Nastąpił problem ze zmianą danych.', { variant: 'error' })
    })
  }
  const handleChangeData = () => {
      return (
        <>
          <Grid container spacing={4}>
            <Grid item md={6} xs={12}>
              <FormGenerator data={formData} submit={(e) => handleChangeDataSubmit(e)} {...props}/>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography sx={{ color: 'white', fontSize: '25px', textTransform: 'uppercase', fontWeight: '600', marginBottom: '20px' }}>
                Funkcjonalności dodatkowe na koncie
              </Typography>
              <Button onClick={() => history.push('/deleteAccount')} variant="contained">
                Usuń swoje konto
              </Button>
            </Grid>
          </Grid>
        </>
      )
  }
  const dataTabs = [
    {
      name: t.profilePage.changeData,
      value: () => handleChangeData(),
    },
  ]
  useEffect(() => {
    const pathname = window.location.pathname.search('deleteAccount')
    if (pathname > 0) {
      enqueueSnackbar(t.profilePage.toasts.success, { variant: 'success' })
    }
    return () => clearTimeout()
  }, [])
  return (
    <>
      <div style={style.root}>
        <Tabs data={dataTabs}/>
      </div>
    </>
  )
}

export default ProfilePage
