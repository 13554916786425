import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

const RegulationPage = () => {
  const style = {
    root: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      padding: '100px',
      minHeight: 'calc(100vh - 120px)'
    },
    title: {
      fontSize: '28px',
      textAlign: 'center',
      width: '100%',
      fontWeight: 'bold',
      marginBottom: '50px'
    },
    text: {
      fontSize: '18px',
      marginBottom: '20px'
    }
  }
  return (
    <Box sx={style.root}>
      <Typography sx={style.title}>REGULAMIN SERWISU</Typography>
      <Typography sx={style.title}>
        POSTANOWIENIA OGÓLNE
      </Typography>
      <Typography sx={style.text}>
        1. Sklep Internetowy Pizzeria Uno w Koszalinie działający pod adresami
        www.aplikacja.unokoszalin.pl i www.unokoszalin.pl prowadzony jest przez Krowa
        Burger Sp. z o.o. w Koszalinie, przy ul. Piastowskiej 21, 75-400 Koszalin, wpisaną do
        rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy w Koszalinie, IX Wydział
        Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS: 0000890502,
        posiadającą numer NIP: 6692560898 oraz numer Regon: 38846208500000.
        <br/>
        2. Niniejszy „Regulamin Sklepu Internetowego” określa zasady dokonywania zakupów na
        stronie internetowej lokalu gastronomicznego - Pizzeria Uno Koszalin, przy ul.
        Bolesława Chrobrego 18, 75-063 Koszalin oraz aplikacji do zamawiania
        pizzy online z dostawą do domu lub odbioru w restauracji Pizzeria Uno
        Koszalin a w szczególności zasady i tryb zawierania umów sprzedaży na odległość za
        pośrednictwem Sklepu.
        <br/>
        3. Każdy Użytkownik korzystający z unokoszalin.pl oraz aplikacji mobilnej
        aplikacja.unokoszalin.pl zobowiązany jest do przestrzegania Regulaminu. Każdy
        Użytkownik przed rozpoczęciem korzystania ze Sklepu Internetowego z unokoszalin.pl
        oraz aplikacji mobilnej aplikacja.unokoszalin.pl powinien zapoznać się z
        postanowieniami niniejszego Regulaminu.
        <br/>
        4. Klient może uzyskać dostęp do niniejszego Regulaminu w każdym czasie za
        pośrednictwem odsyłacza zamieszczonego na stronie głównej Sklepu oraz stronie
        aplikacji unokoszalin.pl, następnie pobrać go i sporządzić jego wydruk.
        <br/>
        5. Każdy Klient korzystający ze sklepu internetowego unokoszalin.pl oraz aplikacji
        mobilnej aplikacja.unokoszalin.pl zobowiązany jest do przestrzegania Regulaminu, w
        szczególności zakazane jest dostarczanie przez Klienta treści o charakterze
        bezprawnym.
        <br/>
      </Typography>
      <Typography sx={style.title}>
        II. DEFINICJE
      </Typography>
      <Typography sx={style.text}>
        Wyrazy lub zwroty pisane w niniejszym Regulaminie z wielkich liter mają znaczenie wskazane
        poniżej:
        <br/>
        1. Aplikacja mobilna – użytkowy program komputerowy, wykonujący określone
        zadania. Aplikacje mobilne to rodzaj oprogramowania, który działa na urządzeniach
        przenośnych smartfonach i tabletach.
        <br/>
        2. Dni – wszystkie dni od poniedziałku do niedzieli włącznie;
        <br/>
        3. Dni Robocze - wszystkie dni od poniedziałku do piątku włącznie, z wyjątkiem dni
        ustawowo wolnych od pracy w Polsce;
        <br/>
        4. Formularz Zamówienia - interaktywny formularz dostępny w Sklepie
        umożliwiający złożenie Zamówienia, w szczególności poprzez dodanie Towarów do
        Koszyka oraz określenie niektórych warunków Umowy Sprzedaży, w szczególności
        sposobu dostawy i płatności;
        <br/>
        5. Klient – użytkownik sklepu internetowego unokoszalin.pl oraz aplikacji mobilnej
        aplikacja.unokoszalin.pl, dostępnych pod adresami internetowymi
        www.aplikacja.unokoszalin.pl i www.unokoszalin.pl, będący Konsumentem,
        Przedsiębiorcą lub Przedsiębiorcą Konsumentem;
        <br/>
        6. Konsument – pełnoletnia osoba fizyczna, posiadająca pełną zdolność do czynności
        prawnych, dokonująca ze Sprzedawcą czynności prawnej, w tym zawarcia umowy
        sprzedaży na odległość, niezwiązanej bezpośrednio z jej działalnością gospodarczą lub
        zawodową;
        <br/>
        7. Koszyk – element Sklepu, w którym widoczne są wybrane przez Klienta Produkty i w
        którym Klient ma możliwość ustalenia i modyfikacji danych Zamówienia, w
        szczególności ilości nabywanych Produktów;
        <br/>
        8. Przedsiębiorca - osoba fizyczna, osoba prawna i jednostka organizacyjna niebędąca
        osobą prawną, której odrębna ustawa przyznaje zdolność prawną, prowadząca we
        własnym imieniu działalność gospodarczą lub zawodową;
        <br/>
        9. Przedsiębiorca-Konsument – osoba fizyczna prowadząca jednoosobową
        działalność gospodarczą, posiadającą wpis do Centralnej Ewidencji Działalności
        Gospodarczej, zawierająca umowę bezpośrednio związaną z jej działalnością
        gospodarczą, ale nie posiadającą dla niej charakteru zawodowego, wynikającego z
        przedmiotu wykonywanej działalności gospodarczej, weryfikowanego w szczególności
        na podstawie przepisów o Centralnej Ewidencji i Informacji o Działalności
        Gospodarczej. Przy czym dotyczy to tylko umów zawieranych na odległość poza lokalem
        przedsiębiorstwa.
        <br/>
        10. Regulamin – niniejszy regulamin „sklepu internetowego unokoszalin.pl oraz aplikacji
        mobilnej aplikacja.unokoszalin.pl, dostępnych pod adresami internetowymi www.apli
        -kacja.unokoszalin.pl i www.unokoszalin.pl,
        <br/>
        11. Sklep – sklep internetowy unokoszalin.pl oraz aplikacja mobilna
        aplikacja.unokoszalin.pl, dostępne pod adresami internetowymi
        www.aplikacja.unokoszalin.pl i www.unokoszalin.pl,
        <br/>
        12. Sprzedawca – Krowa Burger Sp. z o.o. w Koszalinie, przy ul. Piastowskiej 21, 75-400
        Koszalin, wpisaną do rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy w
        Koszalinie, IX Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem
        KRS: 0000890502, posiadającą numer NIP: 6692560898 oraz numer Regon:
        38846208500000;
        <br/>
        13. Towar – rzecz ruchoma, produkt z asortymentu oferowanego przez sklep internetowy
        unokoszalin.pl oraz aplikacji mobilnej aplikacja.unokoszalin.pl, dostępnych pod
        adresami internetowymi www.aplikacja.unokoszalin.pl i www.unokoszalin.pl,
        <br/>
        14. Umowa Sprzedaży - umowa sprzedaży na odległość Towaru zawierana albo zawarta
        między Klientem a Sprzedawcą za pośrednictwem Sklepu;
        <br/>
        15. Zamówienie - oświadczenie woli Klienta składane za pomocą Formularza
        Zamówienia i zmierzające bezpośrednio do zawarcia Umowy Sprzedaży.
        <br/>
      </Typography>
      <Typography sx={style.title}>
        III. WYMOGI TECHNICZNE
      </Typography>
      <Typography sx={style.text}>
        1. Dla prawidłowego skorzystania ze sklepu internetowego unokoszalin.pl oraz aplikacji
        mobilnej aplikacja.unokoszalin.pl, dostępnych pod adresami internetowymi www.apli
        kacja.unokoszalin.pl i www.unokoszalin.pl,potrzebne jest:
        • posiadanie urządzenia końcowego z dostępem do sieci Internet i przeglądarką
        internetową typu Mozilla Firefox, Chrome, Opera lub Internet Explorer 11 (lub nowsza)
        z włączoną obsługą apletów Javy, JavaScript, Adobe Flash Player i Cookies.
        <br/>
        2. Dla złożenia Zamówienia w sklepie internetowym unokoszalin.pl oraz aplikacji
        mobilnej aplikacja.unokoszalin.pl, dostępnych pod adresami internetowymi www.apli
        kacja.unokoszalin.pl i www.unokoszalin.pl,, poza wymogami określonymi w ust. 1,
        niezbędne jest aktywne konto e-mail.
        <br/>
      </Typography>
      <Typography sx={style.title}>
        IV. TOWARY
      </Typography>
      <Typography sx={style.text}>
        1. Towary oferowane w Sklepie są oparte na świeżych produktach spożywczych,
        odpowiednim przygotowaniu oraz pasji do tworzenia oferowanych Towarów.
        <br/>
        2. Towary sprzedawane w Sklepie mogą się nieznacznie różnić od Towarów, które są
        prezentowane na zdjęciach.
        <br/>
        3. Towarów należy używać zgodnie z ich przeznaczeniem.
        <br/>
      </Typography>
      <Typography sx={style.title}>
        V. ZAWARCIE UMOWY SPRZEDAŻY
      </Typography>
      <Typography sx={style.text}>
        Zawarcie Umowy sprzedaży jest możliwe zarówno za pośrednictwem sklepu
        internetowego unokoszalin.pl jak i aplikacji mobilnej aplikacja.unokoszalin.pl, dostęp
        nych pod adresami internetowymi www.aplikacja.unokoszalin.pl i www.unokoszalin.p
        l,
        <br/>
        2. Opisy Towarów wraz z cenami stanowią zaproszenie do zawarcia umowy (informację
        handlową), a nie ofertę w rozumieniu Kodeksu cywilnego. Klient składając Zamówienie
        składa ofertę kupna wskazanego Towaru za cenę i o cechach określonych w opisie
        Towaru.
        <br/>
        3. Klient może składać Zamówienia w Sklepie za pośrednictwem Strony Internetowej
        Sklepu oraz Aplikacji mobilnej przez 7 dni w tygodniu, 24 godziny na dobę, przez cały
        rok. Sklep realizuje Zamówienia złożone w godzinach pracy Sklepu tj. od 12 do 22 od
        poniedziałku do czwartku, od 12 do 23 w piątki i w soboty oraz od 12 do 22 w niedziele.
        <br/>
        4. W celu złożenia Zamówienia za pośrednictwem Strony Internetowej Sklepu Klient:
        <br/>
        • wybiera Towar, którym jest zainteresowany, a następnie klika przycisk &quot;DODAJ DO
        KOSZYKA&quot; znajdujący się pod danym Towarem;
        <br/>
        • przechodzi do „KOSZYKA” , który znajduje się w prawnym górnym rogu Strony
        Internetowej Sklepu;
        <br/>
        • wypełnia Formularz Zamówienia poprzez wpisanie następujących danych: imienia i
        nazwiska, adresu, na który ma nastąpić dostawa Towaru, adresu poczty elektronicznej,
        nr telefonu oraz niezbędnych danych;
        <br/>
        • wybiera sposób dostawy oraz formę płatności;
        <br/>
        • wybiera przycisk „KUPUJĘ I PŁACĘ”.
        <br/>
        5. Zamówienie zostaje złożone w momencie potwierdzenia jego treści i zaakceptowania
        przez Kupującego Regulaminu Sklepu oraz Polityki Prywatności, poprzez zaznaczenie
        odpowiednich okienek.
        <br/>
        6. Po złożeniu Zamówienia, Sprzedawca przesyła na podany przez Klienta adres poczty
        elektronicznej potwierdzenie jego złożenia.
        <br/>
        7. Następnie, po potwierdzeniu złożenia Zamówienia, Sprzedawca przesyła na podany
        przez Klienta adres poczty elektronicznej informację o przyjęciu Zamówienia do
        realizacji. Informacja o przyjęciu Zamówienia do realizacji jest oświadczeniem
        Sprzedawcy o przyjęciu oferty i z chwilą jego otrzymania przez Klienta zostaje zawarta
        Umowa sprzedaży.
        <br/>
      </Typography>
      <Typography sx={style.title}>
        VI. CENA TOWARÓW
      </Typography>
      <Typography sx={style.text}>
        1. Ceny podane w Sklepie są podane w polskich złotych i są cenami brutto (zawierają
        podatek VAT). Ceny nie zawierają kosztów wysyłki, które są zależne od wybranego przez
        Klienta sposobu płatności i dostawy.
        <br/>
        2. Na całkowitą cenę Zamówienia składają się wskazane w Sklepie cena za Towar oraz
        koszty dostawy Towaru.
        <br/>
        3. Sprzedawca zastrzega sobie prawo zmiany cen Towarów znajdujących się w Sklepie,
        wprowadzania nowych Towarów do sprzedaży, przeprowadzania i odwoływania akcji
        promocyjnych, bądź wprowadzania w nich zmian zgodnie z normami kodeksu
        cywilnego oraz innych ustaw.
        <br/>
        4. Cena Towaru uwidoczniona na stronie Sklepu jest wiążąca w chwili złożenia przez
        Klienta Zamówienia. Cena ta nie ulegnie zmianie niezależnie od zmian cen w Sklepie,
        które mogą się pojawić w odniesieniu do poszczególnych Towarów po złożeniu przez
        Klienta Zamówienia.
        <br/>
        5. Klient na podstawie
        <br/>
      </Typography>
      <Typography sx={style.title}>
        VII. SPOSÓB PŁATNOŚCI
      </Typography>
      <Typography sx={style.text}>
        1. Dostępną w Sklepie metodą płatności jest płatność on-line za pośrednictwem przelewu
        bankowego/płatności ING, przelewy24, PayU, Stripe.
        <br/>
        2. Towar zostanie wysłany Klientowi po zaksięgowaniu płatności. W przypadku braku
        zaksięgowania płatności w ciągu 3 dni roboczych, Zamówienie zostanie automatycznie
        anulowane.
        <br/>
      </Typography>
      <Typography sx={style.title}>
        VII. FUNKCJONALNOŚĆ APLIKACJI MOBILNEJ
        aplikacja.unokoszalin.pl
      </Typography>
      <Typography sx={style.text}>
        1. Aplikacja mobilna, aplikacja.unokoszalin.pl, dostępna pod adresem internetowym
        www.aplikacja.unokoszalin.pl umożliwia składanie zamówień z wyprzedzeniem, np. na
        następny dzień.
        <br/>
        2. Aplikacja mobilna, aplikacja.unokoszalin.pl, dostępna pod adresem internetowym
        <br/>
        3. www.aplikacja.unokoszalin.pl posiada Katalog produktów z opisami i zdjęciami, z
        możliwością filtrowania według ceny, rodzaju ciasta, składników itp.
        <br/>
        4. Aplikacja mobilna, aplikacja.unokoszalin.pl, dostępna pod adresem internetowym
        www.aplikacja.unokoszalin.pl posiada funkcję &quot;Ulubione&quot;, pozwalająca na zapisywanie
        najczęściej zamawianych pizz w specjalnej liście.
        <br/>
        5. Aplikacja mobilna, aplikacja.unokoszalin.pl, dostępna pod adresem internetowym
        www.aplikacja.unokoszalin.pl posiada mapę dostępności dostawy w różnych rejonach
        miasta.
        <br/>
        6. Aplikacja mobilna, aplikacja.unokoszalin.pl, dostępna pod adresem internetowym
        www.aplikacja.unokoszalin.pl posiada system lojalnościowy z rabatami i nagrodami la
        stałych klientów.
        <br/>
        7. Aplikacja mobilna, aplikacja.unokoszalin.pl, dostępna pod adresem internetowym
        www.aplikacja.unokoszalin.pl ma możliwość zapisywania różnych adresów dostawy i
        form płatności.
        <br/>
        8. Aplikacja mobilna, aplikacja.unokoszalin.pl, dostępna pod adresem internetowym
        www.aplikacja.unokoszalin.pl jest zintegrowana z popularnymi systemami płatności
        online.
        <br/>
        9. Aplikacja mobilna, aplikacja.unokoszalin.pl, dostępna pod adresem internetowym
        www.aplikacja.unokoszalin.pl zawiera powiadomienia push z informacjami o
        promocjach i nowościach w ofercie.
        <br/>
        10. Aplikacja mobilna, aplikacja.unokoszalin.pl, dostępna pod adresem internetowym
        www.aplikacja.unokoszalin.pl zawiera funkcję &quot;Zamówienie dla firmy&quot; z możliwością
        dodawania szczegółów dotyczących zamówienia (np. ilość osób, preferencje kulinarne
        itp.)
        <br/>
        11. Aplikacja mobilna, aplikacja.unokoszalin.pl, dostępna pod adresem internetowym
        www.aplikacja.unokoszalin.pl zawiera możliwość składania zamówień grupowych z
        opcją dzielenia rachunku.
        <br/>
        12. Aplikacja mobilna, aplikacja.unokoszalin.pl, dostępna pod adresem internetowym
        www.aplikacja.unokoszalin.pl zawiera wirtualny koszyk, w którym użytkownik może
        dodawać i usuwać produkty przed złożeniem zamówienia.
        <br/>
        13. Aplikacja mobilna, aplikacja.unokoszalin.pl, dostępna pod adresem internetowym
        www.aplikacja.unokoszalin.pl zawiera kalkulator kalorii dla poszczególnych Towarów,
        pozwalający na śledzenie spożywanych kalorii.
        <br/>
        14. Aplikacja mobilna, aplikacja.unokoszalin.pl, dostępna pod adresem internetowym
        www.aplikacja.unokoszalin.pl umożliwia zamawianie Towarów z dostosowanymi
        składnikami (np. bezglutenowe, wegetariańskie itp.).
        <br/>
        15. Aplikacja mobilna, aplikacja.unokoszalin.pl, dostępna pod adresem internetowym
        www.aplikacja.unokoszalin.pl jest zintegrowana z popularnymi aplikacjami do
        zamawiania jedzenia, takimi jak Pyszne.pl, Glovo czy Uber Eats.
        <br/>
        16. Aplikacja mobilna, aplikacja.unokoszalin.pl, dostępna pod adresem internetowym
        www.aplikacja.unokoszalin.pl umożliwia zapisanie swoich preferencji i ustawień w
        specjalnym panelu użytkownika.
        <br/>
        17. Aplikacja mobilna, aplikacja.unokoszalin.pl, dostępna pod adresem internetowym
        www.aplikacja.unokoszalin.pl zawiera system rekomendacji, polecający
        użytkownikowi Towaru na podstawie jego dotychczasowych zamówień.
        <br/>
        18. Aplikacja mobilna, aplikacja.unokoszalin.pl, dostępna pod adresem internetowym
        www.aplikacja.unokoszalin.pl zawiera funkcję &quot;Zamówienie na urodziny&quot; z opcją
        dodania specjalnego życzenia.
        <br/>
        19. Aplikacja mobilna, aplikacja.unokoszalin.pl, dostępna pod adresem internetowym
        www.aplikacja.unokoszalin.pl zawiera Mini gry wewnątrz aplikacji, np.:
        <br/>
        1) Quiz z pytaniami dotyczącymi historii pizzy lub ciekawostkami związanymi z tym
        daniem,
        <br/>
        2) Gra typu &quot;memory&quot;, w której użytkownik musi dopasować składniki do
        odpowiednich pizz.
        <br/>
        20. Aplikacja mobilna, aplikacja.unokoszalin.pl, dostępna pod adresem internetowym
        www.aplikacja.unokoszalin.pl zawiera sklep z akcesoriami, gadżetami do kupienia
        przez użytkowników.
        <br/>
        21. Aplikacja mobilna, aplikacja.unokoszalin.pl, dostępna pod adresem internetowym
        www.aplikacja.unokoszalin.pl zawiera system rekomendacji, Możliwość zapisania się
        do newslettera.
        <br/>
        22. Aplikacja mobilna, aplikacja.unokoszalin.pl, dostępna pod adresem internetowym
        www.aplikacja.unokoszalin.pl zawiera funkcję dodawania innych użytkowników jako
        &quot;znajomych&quot; w aplikacji mobilnej.
        <br/>
        23. Aplikacja mobilna, aplikacja.unokoszalin.pl, dostępna pod adresem internetowym
        www.aplikacja.unokoszalin.pl zawiera funkcję „Chatu” z obsługą Sprzedawcy, oraz
        innymi użytkownikami aplikacji (znajomymi).
        <br/>
      </Typography>
      <Typography sx={style.title}>
        X. REKLAMACJA TOWARU
      </Typography>
      <Typography sx={style.text}>
        1. Sprzedawca jest obowiązany do dostarczenia Kupującemu Towaru bez wad.
        <br/>
        2. Sposób dostawy Towaru jest ustalany w trakcie procesu składania Zamówienia.
        <br/>
        3. Czas realizacji Zamówienia jest liczony od momentu uzyskania zaksięgowania
        płatności.
        <br/>
        4. Na termin dostawy Towaru składa się czas przygotowania oraz czas dostawy Towaru.
        <br/>
        5. Zakupione w Sklepie Towary są wysyłane na terenie Polski.
        <br/>
        6. Istnieje możliwość odbioru osobistego zamówionego Towaru w miejscu wykonywania
        działalności gospodarczej Sprzedawcy tj. przy ul. Bolesława Chrobrego 18, 75-063
        Koszalin.
        <br/>
      </Typography>
      <Typography sx={style.title}>
        XI. PRAWO ODSTĄPIENIA OD UMOWY
      </Typography>
      <Typography sx={style.text}>
        1. Sprzedawca odpowiada wobec Zamawiającego Konsumenta z tytułu niezgodności
        Towaru z Umową na zasadach ustawy z dnia 30 maja 2014 r. – o prawach konsumenta.
        <br/>
        2. Jeżeli towar jest niezgodny z umową, konsument może żądać jego naprawy lub
        wymiany.
        <br/>
        3. Sprzedawca może dokonać wymiany, gdy konsument żąda naprawy, lub przedsiębiorca
        może dokonać naprawy, gdy konsument żąda wymiany, jeżeli doprowadzenie do
        zgodności towaru z umową w sposób wybrany przez konsumenta jest niemożliwe albo
        wymagałoby nadmiernych kosztów dla Sprzedawcy. Jeżeli naprawa i wymiana są
        niemożliwe lub wymagałyby nadmiernych kosztów dla Sprzedawcy, może on odmówić
        doprowadzenia towaru do zgodności z umową.
        <br/>
        4. Przy ocenie nadmierności kosztów dla przedsiębiorcy uwzględnia się wszelkie
        okoliczności sprawy, w szczególności znaczenie braku zgodności towaru z umową,
        wartość towaru zgodnego z umową oraz nadmierne niedogodności dla konsumenta
        powstałe wskutek zmiany sposobu doprowadzenia towaru do zgodności z umową.
        <br/>
        5. Sprzedawca dokonuje naprawy lub wymiany w rozsądnym czasie od chwili, w której
        Sprzedawca został poinformowany przez konsumenta o braku zgodności z umową, i bez
        nadmiernych niedogodności dla Konsumenta, uwzględniając specyfikę towaru oraz cel,
        w jakim Konsument go nabył. Koszty naprawy lub wymiany, w tym w szczególności
        koszty opłat pocztowych, przewozu, robocizny i materiałów, ponosi Sprzedawca.
        <br/>
        6. Konsument udostępnia Sprzedawcy towar podlegający naprawie lub wymianie.
        Przedsiębiorca odbiera od konsumenta towar na swój koszt.
        <br/>
        7. Jeżeli towar został zamontowany przed ujawnieniem się braku zgodności towaru z
        umową, Sprzedawca demontuje towar oraz montuje go ponownie po dokonaniu
        naprawy lub wymiany albo zleca wykonanie tych czynności na swój koszt.
        <br/>
        8. Konsument nie jest zobowiązany do zapłaty za zwykłe korzystanie z towaru, który
        następnie został wymieniony.
        <br/>
        9. Jeżeli towar jest niezgodny z umową, konsument może złożyć oświadczenie o obniżeniu
        ceny albo odstąpieniu od umowy, gdy:
        <br/>
        1) Sprzedawca odmówił doprowadzenia towaru do zgodności z umową,
        <br/>
        2) Sprzedawca nie doprowadził towaru do zgodności z umową,
        <br/>
        3) brak zgodności towaru z umową występuje nadal, mimo że Sprzedawca próbował
        doprowadzić towar do zgodności z umową;
        <br/>
        4) brak zgodności towaru z umową jest na tyle istotny, że uzasadnia obniżenie ceny albo
        odstąpienie od umowy bez uprzedniego skorzystania ze środków ochrony określonych
        w ust. 5.
        <br/>
        5) z oświadczenia Sprzedawcy lub okoliczności wyraźnie wynika, że nie doprowadzi on
        towaru do zgodności z umową w rozsądnym czasie lub bez nadmiernych niedogodności
        dla konsumenta.
        <br/>
        10. Obniżona cena musi pozostawać w takiej proporcji do ceny wynikającej z umowy, w
        jakiej wartość towaru niezgodnego z umową pozostaje do wartości towaru zgodnego z
        umową.
        <br/>
        11. Sprzedawca zwraca konsumentowi kwoty należne wskutek skorzystania z prawa
        obniżenia ceny niezwłocznie, nie później niż w terminie 14 dni od dnia otrzymania
        oświadczenia konsumenta o obniżeniu ceny.
        <br/>
        12. Konsument nie może odstąpić od umowy, jeżeli brak zgodności towaru z umową jest
        nieistotny. Domniemywa się, że brak zgodności towaru z umową jest istotny.
        <br/>
        13. Jeżeli brak zgodności z Umową dotyczy jedynie niektórych towarów dostarczonych na
        podstawie umowy konsument może odstąpić od umowy jedynie w odniesieniu do tych
        towarów, a także w odniesieniu do innych towarów nabytych przez konsumenta wraz z
        towarami niezgodnymi z umową, jeżeli nie można rozsądnie oczekiwać, aby konsument
        zgodził się zatrzymać wyłącznie towary zgodne z umową.
        <br/>
        14. W razie odstąpienia od umowy konsument niezwłocznie zwraca towar Sprzedawcy na
        jego koszt. Sprzedawca zwraca konsumentowi cenę niezwłocznie, nie później niż w
        terminie 14 dni od dnia otrzymania towaru lub dowodu jego odesłania.
        <br/>
        15. Sprzedawca dokonuje zwrotu ceny przy użyciu takiego samego sposobu zapłaty, jakiego
        użył konsument, chyba że konsument wyraźnie zgodził się na inny sposób zwrotu, który
        nie wiąże się dla niego z żadnymi kosztami.
        <br/>
        16. Sprzedawca odpowiada także wobec Zamawiającego tytułu rękojmi za wady fizyczne i
        prawne zakupionego Towaru na zasadach określonych w Kodeksie cywilnym.
        <br/>
        17. Reklamacje dotyczące Towarów, mogą być zgłaszane:
        <br/>
        • na piśmie, na adres siedziby Sprzedawcy,
        <br/>
        • pocztą elektroniczną, na adres e-mail wskazany w potwierdzeniu złożenia Zamówienia
        tj. koszalinuno@gmail.com.
        <br/>
        18. Reklamacja powinna zawierać:
        <br/>
        • dane osoby składającej reklamację (imię i nazwisko, adres korespondencyjny,
        opcjonalnie – adres e-mail i numer telefonu kontaktowego),
        <br/>
        • wskazanie przyczyny reklamacji oraz treści żądania.
        <br/>
        • numer Zamówienia (widnieje w potwierdzeniu przyjęcia Zamówienia),
        <br/>
        • oryginał lub kopia dowodu zakupu (np. paragonu lub faktury) może ułatwić złożenie
        reklamacji, ale nie jest niezbędny do jej złożenia.
        <br/>
        19. O ile krótszy czas rozpatrzenia reklamacji nie wynika z bezwzględnie obowiązujących
        przepisów prawa, reklamacje rozpatrywane są przez Sprzedawcę w terminie do 14 dni
        od ich otrzymania. O ewentualnych brakach Reklamacji Klient zostanie powiadomiony
        niezwłoczne. Wraz z takim zawiadomieniem zostaną przesłane Klientowi informacje, w
        jaki sposób należy uzupełnić braki w zgłoszeniu reklamacyjnym.
        <br/>
        20. O sposobie rozpatrzenia reklamacji Sprzedawca powiadomi osobę składającą
        reklamację listem wysłanym na adres podany w reklamacji albo pocztą elektroniczną –
        w zależności od sposobu złożenia reklamacji.
        <br/>
        21. Reklamacje dotyczące niezgodności Towarów z Umową rozpatrywane są zgodnie z
        ustawą z dnia 30 maja 2014 r. – o prawach konsumenta, zaś reklamacje dotyczące wad
        fizycznych lub prawnych zakupionych Towarów rozpatrywane są zgodnie z przepisami
        Kodeksu cywilnego o rękojmi za wady. W przypadku, o którym mowa w art. 561(2) § 1
        Kodeksu cywilnego, wadliwy Towar powinien zostać dostarczony na adres siedziby
        Sprzedawcy i na koszt Sprzedawcy.
        <br/>
        22. Jeżeli Zamawiający będący Konsumentem lub Przedsiębiorcą-Konsumentem na
        podstawie przepisów o rękojmi za wady zażąda wymiany Towaru lub usunięcia wady
        albo złoży oświadczenie o obniżeniu Ceny, określając kwotę, o którą Cena ma być
        obniżona, a Sprzedawca nie ustosunkuje się do tego żądania w terminie 14 dni, uważa
        się, że Sprzedawca uznał to żądanie za uzasadnione.
        <br/>
        23. Informujemy jednocześnie, iż Klientowi przysługuje możliwość skorzystania z
        pozasądowych sposobów rozpatrywania reklamacji i dochodzenia roszczeń.
        <br/>
        24. W przypadku zauważonych braków w przesyłce, prosimy o niezwłoczny kontakt.
        <br/>
      </Typography>
      <Typography sx={style.title}>
        VII. FUNKCJONALNOŚĆ APLIKACJI MOBILNEJ
        aplikacja.unokoszalin.pl
      </Typography>
      <Typography sx={style.text}>
        1. Klient będący Konsumentem lub Przedsiębiorcą-Konsumentem, który zawarł Umowę
        Sprzedaży, w terminie 14 dni kalendarzowych od dnia, w którym Konsument lub
        Przedsiębiorca-Konsument lub osoba trzecia upoważniona przez Konsumenta lub
        Przedsiębiorcę-Konsumenta do odbioru Towarów weszła w posiadanie Towarów, może
        odstąpić od niej bez podawania przyczyny i bez ponoszenia dodatkowych kosztów, z
        wyjątkiem dodatkowych kosztów wynikających z wybranego przez Konsumenta lub
        Przedsiębiorcę-Konsumenta sposobu dostarczenia innego niż najtańszy zwykły sposób
        dostarczenia oferowany przez Sprzedawcę.
        <br/>
        2. Zwracany Towar nie może nosić śladów użytkowania. Konsument lub PrzedsiębiorcaKonsument ponosi
        odpowiedzialność za zmniejszenie wartości rzeczy będące wynikiem
        korzystania z niej w sposób wykraczający poza konieczny do stwierdzenia charakteru,
        cech i funkcjonowania rzeczy, a Sprzedawca może żądać od Zamawiającego pokrycia
        związanych z tym kosztów.
        <br/>
        3. W celu odstąpienia od Umowy Sprzedaży Konsument lub Przedsiębiorca-Konsument
        musi poinformować Sprzedawcę o swojej decyzji Umowy w drodze jednoznacznego
        oświadczenia przekazanego z wykorzystaniem danych kontaktowych Sprzedawcy,
        wskazanych w potwierdzeniu złożenia Zamówienia. Konsument lub PrzedsiębiorcaKonsument przekazuje oświadczenie
        wysyłając stosowne pismo listownie na adres
        siedziby Sprzedawcy lub pocztą elektroniczną na adres e-mail wskazany w
        potwierdzeniu złożenia Zamówienia.
        <br/>
        4. Dla zachowania terminu odstąpienia od Umowy, określonego w ust. 1 powyżej,
        wystarczające jest wysłanie informacji do Sprzedawcy dotyczącej wykonania
        przysługującego Konsumentowi lub Przedsiębiorcy-Konsumentowi uprawnienia do
        odstąpienia od Umowy przed upływem terminu do odstąpienia od Umowy.
        <br/>
        5. Klient ma obowiązek niezwłocznie, nie później niż w terminie 14 dni kalendarzowych
        od dnia odstąpienia od umowy, zwrócić produkt Sprzedawcy. Klient ponosi
        bezpośrednie koszty zwrotu produktu.
        <br/>
        6. Zwracany Towar należy odpowiednio zabezpieczyć i zapakować oraz odesłać
        kompletny w stanie nienaruszonym na adres Sprzedawcy.
        <br/>
        7. Sprzedawca ma obowiązek niezwłocznie, nie później niż w terminie 14 dni
        kalendarzowych od dnia otrzymania zwrotu Towaru, zwrócić Konsumentowi lub
        Przedsiębiorcy-Konsumentowi wszystkie dokonane przez niego płatności, w tym koszty
        dostawy produktu, z wyjątkiem dodatkowych kosztów wynikających z wybranego przez
        Konsumenta lub Przedsiębiorcę-Konsumenta sposobu dostawy innego niż najtańszy
        zwykły sposób dostawy oferowany przez Sprzedawcę.
        <br/>
        8. Zwrotu płatności Sprzedawca dokona przy użyciu tej samej oryginalnej metody
        płatności dokonanej przez Konsumenta lub Przedsiębiorcę-Konsumenta lub
        tradycyjnym przelewem bankowym, w każdym przypadku Konsument lub
        Przedsiębiorca-Konsument nie poniesie żadnych opłat w związku z tym zwrotem, poza
        kosztami odesłania Towaru do Sprzedawcy.
        <br/>
        9. Prawo odstąpienia od Umowy Sprzedaży nie przysługuje Konsumentowi lub
        Przedsiębiorcy-Konsumentowi w odniesieniu do umowy:
        <br/>
        • w której przedmiotem świadczenia jest rzecz nieprefabrykowana, wyprodukowana
        według specyfikacji Konsumenta lub Przedsiębiorcy-Konsumenta lub służąca
        zaspokojeniu jego zindywidualizowanych potrzeb;
        <br/>
        • w której przedmiotem świadczenia jest rzecz ulegająca szybkiemu zepsuciu lub
        mająca krótki termin przydatności do użycia.
        <br/>
        • w której przedmiotem świadczenia jest rzecz dostarczana w zapieczętowanym
        opakowaniu, której po otwarciu opakowania nie można zwrócić ze względu na
        ochronę zdrowia lub ze względów higienicznych, jeżeli opakowanie zostało otwarte
        po dostarczeniu.;
        <br/>
        • w której przedmiotem świadczenia są rzeczy, które po dostarczeniu, ze względu na
        swój charakter, zostają nierozłącznie połączone z innymi rzeczami;
        <br/>
        • w której przedmiotem świadczenia są nagrania dźwiękowe lub wizualne albo
        programy komputerowe dostarczane w zapieczętowanym opakowaniu, jeżeli
        opakowanie zostało otwarte po dostarczeniu;
        <br/>
        • o dostarczanie dzienników, periodyków lub czasopism, z wyjątkiem umowy o
        prenumeratę;
        <br/>
        • w której cena lub wynagrodzenie zależy od wahań na rynku finansowym, nad
        którymi przedsiębiorca nie sprawuje kontroli, i które mogą wystąpić przed upływem
        terminu do odstąpienia od umowy;
        <br/>
        • o dostarczanie treści cyfrowych, które nie są zapisane na nośniku materialnym,
        jeżeli spełnianie świadczenia rozpoczęło się za wyraźną zgodą Konsumenta przed
        upływem terminu do odstąpienia od umowy i po poinformowaniu go przez
        przedsiębiorcę o utracie prawa odstąpienia od umowy.
        <br/>
        10. Prawo odstąpienia od umowy zawartej na odległość nie przysługuje podmiotowi
        innemu niż Konsument lub Przedsiębiorca-Konsument.
        <br/>
        11. Uprawnienie do odstąpienia od Umowy zawartej na odległość nie wyłącza uprawnień z
        tytułu rękojmi za wady Towarów.
        <br/>
        12. Prawo odstąpienia od Umowy Sprzedaży przysługuje zarówno Sprzedawcy, jak i
        Klientowi (Konsumentowi lub Przedsiębiorcy-Konsumentowi), w przypadku
        niewykonania przez drugą stronę umowy swojego zobowiązania w terminie ściśle
        określonym.
        <br/>
      </Typography>
      <Typography sx={style.title}>
        XII. DANE OSOBOWE
      </Typography>
      <Typography sx={style.text}>
        1. Regulacje dotyczące ochrony oraz przetwarzania danych osobowych Klientów sklepu
        zostały uregulowane w Polityce Prywatności.
        <br/>
        2. Administratorem danych osobowych przekazanych przez Kupującego podczas
        korzystania ze Sklepu jest Sprzedawca.
        <br/>
        3. Podanie przez Kupującego danych osobowych podczas dokonywania zakupów w
        Sklepie jest całkowicie dobrowolne, ale jednocześnie konieczne do zrealizowania
        Zamówienia.
        <br/>
        4. Kupujący ma prawo wglądu do dotyczących go danych osobowych, a także żądania
        poprawienia ich lub usunięcia, o czym jest mowa w Polityce Prywatności.
        <br/>
        5. Podane przez Kupującego podczas składania i celu realizacji Zamówienia dane osobowe
        będą przetwarzane przez Sprzedawcę tylko w celach, o których mowa w Polityce
        prywatności.
        <br/>
      </Typography>
      <Typography sx={style.title}>
        XII. POZASĄDOWE SPOSOBY ROZPATRYWANIA REKLAMACJI I
        DOCHODZENIA ROSZCZEŃ
      </Typography>
      <Typography sx={style.text}>
        1. W przypadku, gdy postępowanie reklamacyjne nie przyniesie oczekiwanego przez
        Konsumenta lub Przedsiębiorcy-Konsumenta rezultatu, Konsument lub
        Przedsiębiorca-Konsument może skorzystać m.in. z:
        <br/>
        • Mediacji prowadzonej przez właściwy terenowo Wojewódzki Inspektorat Inspekcji
        Handlowej, do którego należy się zwrócić z wnioskiem o mediację. Co do zasady
        postępowanie jest bezpłatne. Wykaz Inspektoratów znajduje się tutaj:
        https://www.uokik.gov.pl/wazne_adresy.php#faq595;
        <br/>
        • pomocy właściwego terenowo stałego polubownego sądu konsumenckiego działającego
        przy Wojewódzkim Inspektoracie Inspekcji Handlowej, do którego należy złożyć
        wniosek o rozpatrzenie sprawy przed sądem polubownym. Co do zasady postępowanie
        jest bezpłatne. Wykaz sądów dostępny jest pod adresem:
        https://www.uokik.gov.pl/wazne_adresy.php#faq596;
        <br/>
        • bezpłatnej pomocy miejskiego lub powiatowego rzecznika konsumentów.
        <br/>
        • internetowej platformy ODR dostępnej pod adresem:
        http://ec.europa.eu/consumers/odr/.
        <br/>
      </Typography>
      <Typography sx={style.title}>
        XIII. POSTANOWIENIA DOTYCZĄCE PRZEDSIĘBIORCÓW
      </Typography>
      <Typography sx={style.text}>
        1. Niniejszy punkt Regulaminu oraz postanowienia w nim zawarte dotyczą wyłącznie
        Klientów i Usługobiorców nie będących Konsumentami lub PrzedsiębiorcamiKonsumentami.
        <br/>
        2. Sprzedawcy przysługuje prawo odstąpienia od Umowy sprzedaży zawartej z Klientem
        niebędącym Konsumentem lub Przedsiębiorcą-Konsumentem w terminie 14 dni
        kalendarzowych od dnia jej zawarcia. Odstąpienie od Umowy Sprzedaży w tym wypadku
        może nastąpić bez podania przyczyny i nie rodzi po stronie Klienta niebędącego
        Konsumentem lub Przedsiębiorcą-Konsumentem żadnych roszczeń w stosunku do
        Sprzedawcy.
        <br/>
        3. W wypadku Klientów nie będących Konsumentami lub PrzedsiębiorcamiKonsumentami Sprzedawca ma prawo ograniczyć
        dostępne sposoby płatności, w tym
        także wymagać dokonania przedpłaty w całości albo części i to niezależnie od wybranego
        przez Klienta sposobu płatności oraz faktu zawarcia Umowy Sprzedaży.
        <br/>
        4. Z chwilą wydania przez Sprzedawcę Produktu przewoźnikowi przechodzą na Klienta nie
        będącego Konsumentem lub Przedsiębiorcą-Konsumentem korzyści i ciężary związane
        z Towarem oraz niebezpieczeństwo przypadkowej utraty lub uszkodzenia Towaru.
        Sprzedawca w takim wypadku nie ponosi odpowiedzialności za utratę, ubytek lub
        uszkodzenie Produktu powstałe od przyjęcia go do przewozu aż do wydania go Klientowi
        oraz za opóźnienie w przewozie przesyłki.
        <br/>
        5. W razie przesłania Towaru do Klienta za pośrednictwem przewoźnika Klient nie będący
        Konsumentem lub Przedsiębiorcą-Konsumentem obowiązany jest zbadać przesyłkę w
        czasie i w sposób przyjęty przy przesyłkach tego rodzaju. Jeżeli stwierdzi, że w czasie
        przewozu nastąpił ubytek lub uszkodzenie Towaru, obowiązany jest dokonać wszelkich
        czynność niezbędnych do ustalenia odpowiedzialności przewoźnika.
        <br/>
        6. Zgodnie z art. 558 § 1 Kodeksu Cywilnego odpowiedzialność Sprzedawcy z tytułu rękojmi
        za Produkt wobec Klienta nie będącego Konsumentem lub PrzedsiębiorcąKonsumentem zostaje wyłączona.
        <br/>
        7. W wypadku Usługobiorców nie będących Konsumentami lub PrzedsiębiorcamiKonsumentami Usługodawca może
        wypowiedzieć umowę o świadczenie Usługi
        Elektronicznej ze skutkiem natychmiastowym i bez wskazywania przyczyn poprzez
        przesłanie Usługobiorcy stosownego oświadczenia.
        <br/>
        8. Odpowiedzialność Usługodawcy/Sprzedawcy w stosunku do Usługobiorcy/Klienta nie
        będącego Konsumentem lub Przedsiębiorcą-Konsumentem, bez względu na jej
        podstawę prawną, jest ograniczona – zarówno w ramach pojedynczego roszczenia, jak
        również za wszelkie roszczenia w sumie – do wysokości zapłaconej ceny oraz kosztów
        dostawy z tytułu Umowy Sprzedaży, nie więcej jednak niż do kwoty jednego tysiąca
        złotych. Sprzedawca ponosi odpowiedzialność w stosunku do Klienta nie będącego
        Konsumentem lub Przedsiębiorcą-Konsumentem tylko za typowe szkody
        przewidywalne w momencie zawarcia umowy i nie ponosi odpowiedzialności z tytułu
        utraconych korzyści w stosunku do Klienta nie będącego Konsumentem lub
        Przedsiębiorcą-Konsumentem.
        <br/>
        9. Wszelkie spory powstałe pomiędzy Sprzedawcą, a Klientem niebędącym Konsumentem
        lub Przedsiębiorcą-Konsumentem zostają poddane sądowi właściwemu ze względu na
        siedzibę Sprzedawcy.
        <br/>
      </Typography>
      <Typography sx={style.title}>
        XIV. POSTANOWIENIA KOŃCOWE
      </Typography>
      <Typography sx={style.text}>
        1. Każdorazowo składane w Sklepie Zamówienie stanowi odrębną Umowę Sprzedaży i
        wymaga osobnej akceptacji Regulaminu. Umowa zawierana jest na czas i w celu
        realizacji Zamówienia.
        <br/>
        2. Umowy zawierane na podstawie Regulaminu zawierane są w języku polskim.
        <br/>
        3. Administrator zastrzega sobie prawo zmiany niniejszego Regulaminu. Zmiany nie
        mogą naruszać praw Klientów, wynikających z Zamówień złożonych przed
        wprowadzeniem zmiany. Zmiana Regulaminu wchodzi w życie w terminie 14 dni od
        opublikowania w Serwisie.
        <br/>
        4. W przypadku wątpliwości związanych z interpretacją niniejszego regulaminu, należy go
        interpretować w taki sposób, aby jego wykładnia była zgodna z powszechnie
        obowiązującymi przepisami prawa.
        <br/>
        5. Żadne z postanowień niniejszego regulaminu nie wyłącza lub w żadnym stopniu nie
        ogranicza uprawnień Konsumenta lub Przedsiębiorcy-Konsumenta wynikających z
        przepisów prawa.
        <br/>
        6. W sprawach nieuregulowanych w niniejszym Regulaminie mają zastosowanie przepisy
        Kodeksu cywilnego, przepisy Ustawy o świadczeniu usług drogą elektroniczną oraz inne
        właściwe przepisy prawa polskiego.
        <br/>
      </Typography>
    </Box>
  )
}

export default RegulationPage
