import moment from 'moment'
import DateHelper from './DateHelper'

export default {
  getDiffDate: (date1, date2) => {
    const dates1 = new Date(date1)
    const dates2 = new Date(date2)
    return Math.ceil(Math.abs(dates2.getTime() - dates1.getTime()) / (1000 * 3600 * 24))
  },
  getNameDay: (date) => {
    const days = ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota']
    return days[date?.getDay()]
  },
  getDateToday: () => new Date(),
  getDaysInMonth: (month, year) => new Date(year, month, 0).getDate(),
  getFormattedDateByFormat: (date, format) => moment(date).format(format),
  getDaysBetweenTwoDates: (dateStart, dateEnd) => {
    const start = dateStart ? moment(dateStart) : moment()
    const end = dateEnd ? moment(dateEnd) : moment()
    return start.diff(end)
  },
  getTranslatedTimeBetweenTwoDates: (dateStart, dateEnd) => {
    const diff = DateHelper.getDaysBetweenTwoDates(dateStart, dateEnd)
    const days = moment.duration(diff, 'milliseconds').asDays()
    const hours = Math.floor(moment.duration(diff, 'milliseconds').asHours())
    const minutes = Math.floor(
      moment.duration(diff - hours * 3600000, 'milliseconds').asMinutes()
    )
    return Math.floor(days) > 1
      ? `${Math.floor(days)} dni`
      : `${hours} godzin ${minutes} minut`
  },
  timeDomainWhenLost: (time) => {
    const timeDate = new Date(
      time.split('.')[0],
      time.split('.')[1],
      time.split('.')[2]
    )
    return DateHelper.getTranslatedTimeBetweenTwoDates(timeDate, DateHelper.getDateToday()) + ', ' + time
  },
}
