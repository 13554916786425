import FormGenerator from '../../components/organisms/FormGenerator/FormGenerator'
import UserHelper from '../../helpers/UserHelper/UserHelper'
import { useSnackbar } from 'notistack'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import LocalStorageHelper from '../../helpers/LocalStorageHelper/LocalStorageHelper'
import { Button } from '@mui/material'

const RememberPage = (props) => {
  const t = LocalStorageHelper.get('translation')
  const { enqueueSnackbar } = useSnackbar()
  const formData = {
    elements: [
      {
        name: 'email',
        type: 'email',
        label: t.globals.inputs.email.label,
        placeholder: 'Wpisz email',
        validationType: 'string',
      },
      {
        type: 'button',
        value: t.rememberPage.buttons.reset,
      }
    ],
  }
  const handleRemember = (e) => {
    UserHelper.remember(e).then(res => {
      if (res) enqueueSnackbar('Wysłano wiadomość poprawnie, sprawdź email i zmień hasło.', { variant: 'success' })
      else enqueueSnackbar('Problem z wysłaniem wiadomości email.', { variant: 'error' })
    })
  }
  const style = {
      root: {
        padding: BreakpointsHelper.isMobile() ? '15px' : '50px',
        fontSize: BreakpointsHelper.isMobile() ? '24px' : '25px',
        fontWeight: 700,
        minWidth: BreakpointsHelper.isMobile() ? 'none' : '320px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'center',
      },
      icon: {
          marginRight: '5px',
      },
  }
  return (
		<div style={style.root}>
			<FormGenerator data={formData} submit={(e) => handleRemember(e)} {...props}/>
      <Button
          sx={{ marginBottom: '10px', color: '#00CAE6', fontWeight: '600' }}
          fullWidth
          onClick={() => props.location.history.push('/login')}
      >
        {t.rememberPage.buttons.login}
      </Button>
		</div>
  )
}

export default RememberPage
