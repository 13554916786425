import firebase from 'firebase/compat/app'
import {
  createUserWithEmailAndPassword,
  getAuth,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  deleteUser,
} from 'firebase/auth'
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  setDoc,
  updateDoc,
  deleteDoc,
} from 'firebase/firestore'
import LocalStorageHelper from './helpers/LocalStorageHelper/LocalStorageHelper'
import UserHelper from './helpers/UserHelper/UserHelper'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
}

// eslint-disable-next-line jest/require-hook
const app = firebase.initializeApp(firebaseConfig)

const auth = getAuth()
const db = getFirestore()

const createDateString = () => {
  const date = new Date()
  const year = date.getFullYear()
  const month = date.getMonth()
  const day = date.getDate()
  const hour = date.getHours()
  const min = date.getMinutes()
  const sec = date.getSeconds()
  return `${year}-${month}-${day}, ${hour}:${min}:${sec}`
}

export const signOutFirebase = () => signOut(auth).then((res) => res).catch((error) => console.error(error.code, error.message))

export const getAllUsers = async () => await getAllDataFromPath('users').then((res) => res)

export const getAllNews = async () => await getAllDataFromPath('news').then((res) => res)

export const getAllCodes = async () => await getAllDataFromPath('codes').then((res) => res)

export const updateCode = async (idCode, data) => await updateOrCreateDocument('codes', idCode, data).then(res => res)

export const createUser = (data) => {
  return createUserWithEmailAndPassword(auth, data.email, data.password).then(async (userCredential) => {
    const user = await userCredential?.user
    if (user) {
      sendEmailVerified(user)
      return addDocument(data, user).then(res => res)
    }
  }).catch((error) => console.error(error.code, error.message))
}

export const changeDataUser = async (email, data) => {
  const docRef = doc(db, 'users', email)
  await updateDoc(docRef, {
    name: data?.name,
    phone: data?.phone,
    street: data?.street,
    apartment: data?.apartment,
    house: data?.house,
    defaultLang: data?.defaultLang,
  })
  return true
}

export const sendEmailVerified = (userCredential) => {
  sendEmailVerification(userCredential).then(() => LocalStorageHelper.set('emailForSignIn', true)).catch((error) => console.error(error.code, error.message))
}

export const resetPassword = (email) => {
  return sendPasswordResetEmail(auth, email).then(() => true).catch((error) => console.error(error.code, error.message))
}

export const updateOrCreateDocument = async (path, pathSegment, data) => {
  if (pathSegment) {
    await setDoc(doc(db, path, pathSegment), data)
  } else {
    await addDoc(collection(db, path), data)
  }
  return data
}

export const getDataFromPath = async (path, pathSegments) => {
  const docRef = doc(db, path, pathSegments)
  const docSnap = await getDoc(docRef)
  if (docSnap.exists()) return docSnap.data()
  return {}
}

export const addDocument = async (data, user) => {
  const sendData = {
    uid: user?.uid,
    email: data?.email || user?.email || '',
    phone: data?.phone || user?.phoneNumber || '',
    name: `${data?.firstName} ${data?.secondName}` || user?.name || '',
    role: 'user',
  }
  return await updateOrCreateDocument('users', data.email || user.email, sendData).then((res) => {
    if (res) {
      LocalStorageHelper.set('user', sendData)
      return sendData
    }
  })
}

export const addDataToPath = async (element, data, path) => {
  return await updateOrCreateDocument(path, element, data).then((res) => res)
}

export const addNews = async (data) => {
  data.email = LocalStorageHelper.get('user')?.email
  return await addDataToPath(createDateString(), data, 'news')
}

export const addCode = async (data) => {
  data.used = false
  return await addDataToPath(createDateString(), data, 'codes')
}

export const addProduct = async (data) => {
  console.log(data)
  return await addDoc(collection(db, data?.type), data)
}

export const updateUsers = async (data) => await updateOrCreateDocument('users', data?.email, data).then(res => res)

export const getAllDataFromPath = async (path) => {
  const allDocs = []
  const querySnapshot = await getDocs(collection(db, path))
  await querySnapshot.forEach((doc) => allDocs.push({ id: doc.id, data: doc.data() }))
  return allDocs
}

export const refreshToken = () => auth?.currentUser?.getIdToken()

export const loginUser = (email, password) => {
  const auth = getAuth()
  return signInWithEmailAndPassword(auth, email, password).then((userCredential) => {
    return userCredential?.user
  }).catch((error) => console.error(error.code, error.message))
}

export const deleteAccount = async () => {
  const auth = getAuth()
  const user = auth.currentUser
  const email = LocalStorageHelper.get('user')?.email
  await deleteDoc(doc(db, 'users', email))
  await deleteUser(user)
  LocalStorageHelper.clear()
  window.open('/login', '_self')
}

export default firebase
