import ReactToPdf from 'react-to-pdf'
import { createRef, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { ReactComponent as Logo } from '../../../assets/images/logo.svg'
import DateHelper from '../../../helpers/DateHelper/DateHelper'
import { Button } from '@mui/material'

const Receipt = ({
    payWith = { currency: 'pln', value: 'zł' },
    useToPay = 'Gotówka',
    discount = true,
    discountType = 'count',
    discountValue = 20,
  }) => {
  const ref = createRef()
  const [currency, setCurrency] = useState(1)
  const [price, setPrice] = useState(0)
  const [ptuA, setPtuA] = useState(0)
  const [allA, setAllA] = useState(0)
  const [ptuB, setPtuB] = useState(0)
  const [allB, setAllB] = useState(0)
  const [ptuC, setPtuC] = useState(0)
  const [allC, setAllC] = useState(0)
  const style = {
    root: {
      width: '420px',
      height: 'auto',
      padding: '30px',
      border: '1px solid #e3e3e3',
      marginTop: '20px',
    },
    count: {
      fontSize: '28px',
      textTransform: 'uppercase',
      fontWeight: '600',
    },
  }
  const products = [
    {
      name: 'Grawitacja',
      price: 23,
      count: 1,
      ptu: 'b',
    },
    {
      name: 'Obcy',
      price: 29,
      count: 2,
      ptu: 'b',
    },
    {
      name: 'Apollo13',
      price: 28,
      count: 4,
      ptu: 'b',
    },
    {
      name: 'Opakowanie',
      price: 1.5,
      count: 3,
      ptu: 'c',
    },
    {
      name: 'Dowóz telefon',
      price: 5,
      count: 1,
      ptu: 'a',
    },
  ]
  const handlePrice = () => {
    let tempPrice = 0
    products.map(el => {
      const productPrice = Number(el.price)
      tempPrice = tempPrice + (productPrice * el.count)
      return true
    })
    setPrice(tempPrice)
  }
  const handlePTU = () => {
    let A = 0
    let B = 0
    let C = 0
    products?.map(el => {
      if (el.ptu === 'a') A = A + (el.price * el.count)
      if (el.ptu === 'b') B = B + (el.price * el.count)
      if (el.ptu === 'c') C = C + (el.price * el.count)
      return true
    })
    setPtuA(A * 0.23)
    setAllA(A)
    setPtuB(B * 0.08)
    setAllB(B)
    setPtuC(C * 0.05)
    setAllC(C)
  }
  const handleCurrency = () => {
    if (payWith.currency === 'eur') setCurrency(0.21)
    if (payWith.currency === 'pln') setCurrency(1)
  }
  useEffect(() => {
    if (products && payWith) {
      handlePrice()
      handlePTU()
      handleCurrency()
    }
  }, [payWith, products])
  return (
    <div className="Paragon">
      <ReactToPdf targetRef={ref} filename="div-blue.pdf">
        {(props) => (
          <Button variant="outlined" onClick={(e) => props.toPdf(e)}>Generuj paragon</Button>
        )}
      </ReactToPdf>
      <Box
        ref={ref}
        sx={style.root}
      >
        <Box sx={{ textAlign: 'center' }}>
          <Logo />
          <Typography>Pizzeria Uno Koszalin</Typography>
          <Typography>Bolesława Chrobrego 18, 75-063 Koszalin</Typography>
          <Typography>NIP: 669 256 08 98</Typography>
        </Box>
        <hr />
        <Typography sx={{ textAlign: 'center' }}>Paragon Fiskalny</Typography>
        <Typography sx={{ textAlign: 'center' }}>Data: {DateHelper.getFormattedDateByFormat(DateHelper.getDateToday(), 'HH:mm, DD-MM-YYYY')}</Typography>
        <Typography sx={{ textAlign: 'center' }}>Numer zamówienia: {DateHelper.getFormattedDateByFormat(DateHelper.getDateToday(), 'HHmmssDDMMYYYY')}</Typography>
        <hr/>
        {
          products?.map((el, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography sx={{ flex: 1 }}>{el.name}</Typography>
              <Typography sx={{ flex: 1 }}>{el.count} * {(el.price * currency).toFixed(2)} {payWith.value.toUpperCase()}</Typography>
              <Typography sx={{ flex: 1, textAlign: 'right' }}>{(el.price * currency).toFixed(2)} {payWith.value.toUpperCase()} | {el.ptu.toUpperCase()}</Typography>
            </Box>
          ))
        }
        <hr/>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography>Sprzedaż opodatkowana A</Typography>
          <Typography>{(allA * currency).toFixed(2)} {payWith.value.toUpperCase()}</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography>Sprzedaż opodatkowana B</Typography>
          <Typography>{(allB * currency).toFixed(2)} {payWith.value.toUpperCase()}</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography>Sprzedaż opodatkowana C</Typography>
          <Typography>{(allC * currency).toFixed(2)} {payWith.value.toUpperCase()}</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography>Kwota PTU A 23%</Typography>
          <Typography>{(ptuA * currency).toFixed(2)} {payWith.value.toUpperCase()}</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography>Kwota PTU B 8%</Typography>
          <Typography>{(ptuB * currency).toFixed(2)} {payWith.value.toUpperCase()}</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography>Kwota PTU C 5%</Typography>
          <Typography>{(ptuC * currency).toFixed(2)} {payWith.value.toUpperCase()}</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography>Suma PTU</Typography>
          <Typography>{((ptuA + ptuB + ptuC) * currency).toFixed(2)} {payWith.value.toUpperCase()}</Typography>
        </Box>
        {
          discount && (
            <>
              <hr/>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Typography>Zniżka</Typography>
                <Typography>Dostępna</Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Typography>Rodzaj zniżki</Typography>
                <Typography>{discountType === 'count' ? 'Kwotowa' : 'Procentowa'}</Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Typography>Wysokość zniżki</Typography>
                <Typography>{discountValue.toFixed(2)}</Typography>
              </Box>
            </>
          )
        }
        <hr/>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography>Rozliczenie płatności</Typography>
          <Typography>{useToPay}</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography>Kasjer</Typography>
          <Typography>Jan Szymański</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography>Numer kasy</Typography>
          <Typography>F0312/113 #0 KO</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography>Nr Sys:</Typography>
          <Typography>00123618</Typography>
        </Box>
        <hr/>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography>SUMA:</Typography>
          <Typography>{payWith.currency.toUpperCase()} {((ptuA + ptuB + ptuC + price) * currency).toFixed(2)}</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography sx={style.count}>DO ZAPŁATY:</Typography>
          <Typography sx={style.count}>{payWith.currency.toUpperCase()} {((ptuA + ptuB + ptuC + price - discountValue) * currency).toFixed(2)}</Typography>
        </Box>
        <Box>
          <Typography sx={{ textAlign: 'center', fontSize: '18px', fontWeight: '400', marginTop: '20px' }}>Pizzeria Uno zaprasza ponownie 🍕</Typography>
        </Box>
      </Box>
    </div>
  )
}

export default Receipt
