import { getAllDataFromPath } from '../../firebase'
import NotificationService from './NotificationService'

export default {
  set: (number, message) => {
    return fetch(`https://api.mobitex.pl/sms.php?user=${'gramwgastro'}&pass=${'ea990800604b923f9475ad26ea9551d2'}&type=${'unicode'}&number=${`48${number?.replace(' ', '').replace(' ', '').replace(' ', '')}`}&text=${message?.replace(' ', '+')}`, {
      method: 'POST',
      mode: 'no-cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'Access-control-allow-origin': '*',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    })
  },
  sendToUsersFromEvent: async (e, numbers) => {
    return await getAllDataFromPath('users').then((res) => {
      if (res) {
        numbers?.map((el) => {
          if (e?.notificationText) {
            NotificationService.set(el, e.notificationText)
          }
          return true
        })
      }
    })
  },
  sendToAllUsers: async (message) => {
    return await getAllDataFromPath('users').then((res) => {
      if (res) {
        const numbers = []
        res?.map(el => {
          if (el?.data?.phone) {
            const withNoSpaces = el?.data?.phone.toString().trim().replace(' ', '')
            if (withNoSpaces.length >= 9) {
              numbers.push(withNoSpaces)
            }
          }
          return true
        })
        numbers?.map((el) => {
          NotificationService.set(el, message)
          return true
        })
      }
    })
  }
}
