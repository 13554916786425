import { useEffect } from 'react'
import Logo from '../../../assets/images/logo.svg'
import LocalStorageHelper from '../../../helpers/LocalStorageHelper/LocalStorageHelper'
import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'
import MenuIcon from '@mui/icons-material/Menu'
import {
  SupervisorAccountOutlined
} from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import Box from '@mui/material/Box'
import ChangeLang from '../../organisms/ChangeLang/ChangeLang'
import { useHistory } from 'react-router'

const Navigation = ({ defaultLang, handleDrawerClose, open, setOpen, navigationElements, minVersion }) => {
  const t = LocalStorageHelper.get('translation')
  const path = window?.location?.pathname
  const history = useHistory()
  const style = {
    root: {
      paddingTop: 15,
      height: 'calc(100vh - 15px)',
      width: open ? 240 : 72,
      overflow: 'hidden',
      position: 'fixed',
      background: '#030b24',
      display: BreakpointsHelper.isMobile() ? 'none' : 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderRight: '0.5px solid rgba(255, 255, 255, .2)',
    },
    rootMobileOpen: {
      display: 'flex',
      flexDirection: 'column',
      position: 'fixed',
      zIndex: 998,
      bottom: 0,
      width: '100%',
      height: '66px',
      background: '#030b24',
      alignItems: 'center',
      borderTop: '1px solid white',
      justifyContent: 'center',
    },
    rootMobileClose: {
      display: 'none',
    },
    name: {
      display: 'flex',
      flexDirection: 'column',
      width: 235,
      padding: '15px',
    },
    social: {
      textAlign: 'left',
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      padding: BreakpointsHelper.isMobile() ? '0 15px' : '0',
    },
    img: {
      height: '55px',
      marginBottom: '57px',
      padding: open && '15px 0',
    },
    nav: {
      cursor: 'pointer',
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      width: open ? 'calc(100% - 70px)' : '100%',
      justifyContent: open ? 'flex-start' : 'center',
      fontSize: BreakpointsHelper.isMobile() ? '16px' : '21px',
      padding: open ? '20px 35px' : '20px 15px',
      fontWeight: 700,
      lineHeight: '26px',
      textTransform: 'uppercase',
    },
    navMobile: {
      cursor: 'pointer',
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      width: '100%',
      justifyContent: 'center',
      fontSize: '14px',
      flexDirection: 'column',
      fontWeight: 700,
      lineHeight: '26px',
      textTransform: 'uppercase',
    },
    navTitle: {
      marginLeft: '20px',
      fontSize: '20px',
      color: 'white',
    },
    navTitleMobile: {
      fontSize: '13px',
      color: 'white',
    },
    navigationList: {
      maxHeight: 'calc(100vh - 200px)',
      width: '100%',
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: '20px',
      flexDirection: 'column',
      marginTop: BreakpointsHelper.isMobile() && '50px',
    },
    navigationListMobile: {
      width: '100%',
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    burger: {
      position: 'fixed',
      top: '0',
      background: '#030b24',
      zIndex: 999,
      width: 'calc(100% - 20px)',
      height: '50px',
      boxShadow: 'rgb(0 0 0 / 15%) 2px 2px 5px 2px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '10px',
    },
    menu: {
      width: 40,
      height: 40,
      color: 'white',
      cursor: 'pointer',
    },
  }
  const handleOpen = (url, target = '_self', outSide = null) => {
    handleDrawerClose()
    if (BreakpointsHelper.isMobile()) setOpen(false)
    if (outSide) window.open(url, target)
    else history?.push(url)
  }
  useEffect(() => {
    if (open) document.body.classList.add('open')
    else if (document.body.classList.contains('open')) document.body.classList.remove('open')
  }, [open])
  return (
		<>
			<div className="Navigation" style={BreakpointsHelper.isMobile() ? open ? style.rootMobileOpen : style.rootMobileClose : style.root}>
        <Box sx={BreakpointsHelper.isMobile() ? style.navigationListMobile : style.navigationList}>
          { !BreakpointsHelper.isMobile() && <img src={Logo} alt="logo" style={style.img} /> }
          {
            navigationElements?.map((el, index) => (
              <Tooltip key={index} title={el.title} placement="right">
                <div className={`menuNav ${path === el.link && 'active'}`} onClick={() => handleOpen(el.link)} style={BreakpointsHelper.isMobile() ? style.navMobile : style.nav}>
                  {el.icon}
                  { open && <span style={BreakpointsHelper.isMobile() ? style.navTitleMobile : style.navTitle}>{el.title}</span> }
                </div>
              </Tooltip>
            ))
          }
        </Box>
        {
          !BreakpointsHelper.isMobile() && (
            <Tooltip title={t.globals.nav.langChange} placement="right">
              <div>
                <ChangeLang defaultLang={defaultLang} />
              </div>
            </Tooltip>
          )
        }
			</div>
		</>
  )
}

export default Navigation
