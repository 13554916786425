import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'
import LocalStorageHelper from '../../helpers/LocalStorageHelper/LocalStorageHelper'
import MagicLoader from '../../components/atoms/MagicLoader/MagicLoader'

export const PrivateRoute = (props) => {
  const { component: Component, layout: Layout } = props
  useEffect(() => {
    const user = LocalStorageHelper.get('user')
    if (!user) props.location.location.history.push('/login')
  }, [])
  return <MagicLoader time={1000}><Route render={(e) => <Layout component={Component} location={e} />} /></MagicLoader>
}
