import React, { useEffect, useState } from 'react'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import { addCode, addNews, addProduct, getAllCodes, getAllDataFromPath, getAllUsers, updateCode } from '../../firebase'
import BasicTable from '../../components/atoms/Table/Table'
import Tabs from '../../components/molecules/Tabs/Tabs'
import LocalStorageHelper from '../../helpers/LocalStorageHelper/LocalStorageHelper'
import Receipt from '../../components/organisms/Receipt/Receipt'
import FormGenerator from '../../components/organisms/FormGenerator/FormGenerator'
import { useSnackbar } from 'notistack'
import { QrReader } from 'react-qr-reader'
import { debounce, throttle } from 'throttle-debounce'
import Typography from '@mui/material/Typography'
import { Grid } from '@mui/material'
import Box from '@mui/material/Box'
import NotificationService from '../../services/NotificationService/NotificationService'
import Button from '@mui/material/Button'
import DateHelper from '../../helpers/DateHelper/DateHelper'

const AdminPage = () => {
	const role = LocalStorageHelper.get('user')?.role
	const [users, setUsers] = useState(null)
	const [dataTabs, setDataTabs] = useState([])
	const [scan, setScan] = useState('')
	const [lastScan, setLastScan] = useState('')
	const { enqueueSnackbar } = useSnackbar()
	const style = {
		root: {
			width: BreakpointsHelper.isMobile() ? 'calc(100% - 30px)' : '100%',
		},
	}
	const onScan = (e) => {
		if (e?.text?.length > 0 && e?.text !== lastScan) setScan(e?.text)
	}
	const handleNews = (e) => {
		addNews(e).then((res) => {
			if (res) enqueueSnackbar('Udało się poprawnie dodać aktualność.', { variant: 'success' })
			else enqueueSnackbar('Problem z dodaniem aktualności.', { variant: 'error' })
		}).catch(() => enqueueSnackbar('Problem z dodaniem aktualności.', { variant: 'error' }))
	}
	const handleCodes = (e) => {
		addCode(e).then((res) => {
			if (res) enqueueSnackbar('Udało się poprawnie dodać kod.', { variant: 'success' })
			else enqueueSnackbar('Problem z dodaniem kodu.', { variant: 'error' })
		}).catch(() => enqueueSnackbar('Problem z dodaniem kodu.', { variant: 'error' }))
	}
	const toastsShow = (res) => {
		if (res) enqueueSnackbar('Udało się poprawnie dodać.', { variant: 'success' })
		else enqueueSnackbar('Problem z dodaniem.', { variant: 'error' })
	}
	const updateUsers = () => {
		getAllUsers().then((res) => {
			if (res) {
				const tempData = []
				res?.map(el => {
					const element = el?.data
					tempData.push({
						uid: element?.uid,
						email: element?.email,
						name: element?.name,
						phone: element?.phone,
						role: element?.role,
					})
					return true
				})
				setUsers(tempData)
			}
		})
	}
	const generateTabs = () => {
		const allTabs = []
		if (role === 'admin') {
			allTabs.push({
				name: 'Lista użytkowników',
				value: () => (
					<>
						{users && <BasicTable data={users} collection={'users'} callback={() => updateUsers()}/>}
					</>
				),
			})
		}
		if (role === 'admin') {
			allTabs.push({
				name: 'System paragonowy',
				value: () => (
					<>
						<Receipt/>
					</>
				),
			})
		}
		if (role === 'admin' || role === 'service') {
			allTabs.push({
				name: 'Skanowanie uno kodów',
				value: () => (
					<QrReader
						delay={1000}
						facingMode="rear"
						style={{ width: '100%', padding: 0 }}
						onResult={(e) => onScan(e)}
						onError={() => true}
						constraints={{ facingMode: 'environment' }}
					/>
				)
			})
		}
		if (role === 'admin') {
			allTabs.push({
				name: 'Dodanie aktualności',
				value: () => {
					const formData = {
						elements: [
							{
								name: 'name',
								type: 'text',
								label: 'Nazwa aktualności',
								validationType: 'string',
							},
							{
								name: 'url',
								type: 'text',
								label: 'URL Link',
								validationType: 'string',
							},
							{
								name: 'urlImage',
								type: 'text',
								label: 'URL Zdjęcia',
								validationType: 'string',
							},
							{
								type: 'button',
								value: 'Dodaj aktualność',
							},
						]
					}
					return (
						<>
							<FormGenerator data={formData} submit={(e) => handleNews(e)} />
						</>
					)
				},
			})
			allTabs.push({
				name: 'Dodanie unoKodu',
				value: () => {
					const formData = {
						elements: [
							{
								name: 'name',
								type: 'text',
								label: 'Nazwa unoKodu',
								validationType: 'string',
							},
							{
								name: 'place',
								type: 'select',
								label: 'Rodzaj',
								items: [{ value: 'here', name: 'Na miejscu' }],
								validationType: 'string',
							},
							{
								name: 'date',
								type: 'date',
								label: 'Czas życia kuponu',
								validationType: 'string',
							},
							{
								type: 'button',
								value: 'Dodaj kod',
							},
						]
					}
					return <FormGenerator data={formData} submit={(e) => addCode(e).then((res) => toastsShow(res))} />
				},
			})
			allTabs.push({
				name: 'Powiadomienia',
				value: () => {
					const handleNotifications = (e) => {
						if (e?.name) {
							NotificationService.sendToAllUsers(e?.name).then(() => {
								enqueueSnackbar('Udało się wysłać powiadomienia do wszystkich poprawnie.', 'success')
							})
						}
					}
					const formData = {
						elements: [
							{
								name: 'name',
								label: 'Text widomości do powiadomienia',
								value: 'Nowe kody zniżkowe, https://aplikacja.unokoszalin.pl',
								type: 'text',
							},
							{
								type: 'button',
								value: 'Wyślij',
							}
						]
					}
					return (
						<Box>
							<Grid container spacing={4}>
								<Grid item md={4} xs={12}>
									<Typography sx={{ marginBottom: '20px', fontSize: '20px' }}>Powiadomienia od wszystkich o nowych kodach zniżkowych</Typography>
									<FormGenerator data={formData} submit={(e) => handleNotifications(e)} />
								</Grid>
							</Grid>
						</Box>
					)
				}
			})
			allTabs.push({
				name: 'Dodanie produktu',
				value: () => {
					const formData = {
						elements: [
							{
								name: 'type',
								type: 'select',
								label: 'Rodzaj',
								items: [
									{ value: 'pizza', name: 'pizza' },
									{ value: 'adds', name: 'adds' },
									{ value: 'sauce', name: 'sauce' },
									{ value: 'delivery', name: 'delivery' },
									{ value: 'drink', name: 'drink' },
									{ value: 'beer', name: 'beer' },
									{ value: 'juice', name: 'juice' },
									{ value: 'water', name: 'water' },
								],
								validationType: 'string',
							},
							{
								name: 'name',
								type: 'text',
								label: 'Nazwa',
								validationType: 'string',
							},
							{
								name: 'price',
								type: 'text',
								label: 'Cena',
								validationType: 'string',
							},
							{
								name: 'text',
								type: 'select',
								label: 'Podatek',
								items: [
									{ value: '0.05', name: '5%' },
									{ value: '0.08', name: '8%' },
									{ value: '0.23', name: '23%' },
								],
								validationType: 'string',
							},
							{
								type: 'button',
								value: 'Dodaj',
							},
						]
					}
					return <FormGenerator data={formData} submit={(e) => addProduct(e).then((res) => toastsShow(res))} />
				},
			})
		}
		setDataTabs(allTabs)
	}
	useEffect(() => {
		generateTabs()
	}, [users])
	useEffect(() => {
		if (scan?.length > 0) {
			const idCode = scan.split('/')[0]
			const email = scan.split('/')[1]
			if (idCode && email) {
				getAllCodes().then(async (res) => {
					if (res) {
						let tempData = null
						await res?.map(el => {
							if (el?.id === idCode) {
								tempData = el?.data
							}
							return true
						})
						if (tempData?.used === false) {
							tempData.used = []
						}
						let emailIsExist = false
						if (tempData?.used?.length > 0) {
							if (tempData.used.find(el => el === email)) {
								emailIsExist = true
							}
						}
						if (!emailIsExist) {
							tempData.used.push(email)
							updateCode(idCode, tempData).then((response) => {
								if (response) {
									enqueueSnackbar(`Kupon o id ${idCode} został poprawnie zrealizowany przez ${email}.`, { variant: 'success' })
								}
							})
						} else {
							enqueueSnackbar(`Kupon o id ${idCode} został już wykorzystany przez ${email}.`, { variant: 'warning' })
						}
						setLastScan(scan)
					}
				})
			} else {
				enqueueSnackbar('Kupon wadliwy.', { variant: 'error' })
			}
		}
	}, [scan])
	useEffect(() => {
		updateUsers()
	}, [])
	return (
		<>
			<div style={style.root}>
				{ dataTabs?.length > 0 && <Tabs data={dataTabs}/> }
			</div>
		</>
	)
}

export default AdminPage
