import en from '../../translations/en'
import pl from '../../translations/pl'

export default {
  DEFAULT_LANG: 'pl',
  AVAILABLE_LANGUAGES: ['pl', 'en'],
  LANG: {
    en,
    pl,
  },
  LOCALE_COOKIE_NAME: 'locale',
}
