import { Box, Grid, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import { useHistory } from 'react-router'
import Logo from '../../assets/images/logo.svg'
import { FacebookOutlined, Instagram } from '@mui/icons-material'
import { ReactComponent as Tiktok } from '../../assets/images/icons/tiktok.svg'

const ContactPage = () => {
  const history = useHistory()
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Grid container spacing={4}>
        <Grid item lg={4} md={4} xs={6}>
          <Typography sx={{ fontWeight: '900', fontSize: '22px', lineHeight: '27px', marginBottom: '18px' }}>
            Pizzeria UNO<br/>Koszalin
          </Typography>
          <Typography>
            ul. Bolesława Chrobrego 18
          </Typography>
          <Typography sx={{ marginBottom: '18px' }}>
            ul. Chałubińskiego 10C
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <FacebookOutlined
              sx={{ marginRight: '20px', cursor: 'pointer' }}
              onClick={() => window.open('https://www.facebook.com/unokoszalin', '_blank')}
            />
            <Instagram
              sx={{ marginRight: '20px', cursor: 'pointer' }}
              onClick={() => window.open('https://www.instagram.com/_u/unokoszalin', '_blank')}
            />
            <Tiktok
              style={{ height: '24px', width: '24px', fill: 'white', marginRight: '20px', cursor: 'pointer' }}
              onClick={() => window.open('https://www.tiktok.com/@unokoszalin', '_blank')}
            />
          </Box>
        </Grid>
        <Grid item lg={4} md={4} xs={6}>
          <img src={Logo} alt="logo" />
        </Grid>
        <Grid item lg={4} md={4} xs={12}>
          <Grid container spacing={4}>
            <Grid item lg={6} md={6} xs={6}>
              <Typography sx={{ fontSize: '22px', lineHeight: '41px', fontWeight: '600' }}>Pon - Czw</Typography>
              <Typography sx={{ fontSize: '22px', lineHeight: '41px', fontWeight: '600' }}>Pt - Sob</Typography>
              <Typography sx={{ fontSize: '22px', lineHeight: '41px', fontWeight: '600' }}>Ndz</Typography>
            </Grid>
            <Grid item lg={6} md={6} xs={6}>
              <Typography sx={{ fontSize: '22px', lineHeight: '41px', fontWeight: '900' }}>12<span style={{ fontSize: '14px', position: 'relative', top: '-6px', left: '3px' }}>00</span> - 22<span style={{ fontSize: '14px', position: 'relative', top: '-6px', left: '3px' }}>00</span></Typography>
              <Typography sx={{ fontSize: '22px', lineHeight: '41px', fontWeight: '900' }}>12<span style={{ fontSize: '14px', position: 'relative', top: '-6px', left: '3px' }}>00</span> - 23<span style={{ fontSize: '14px', position: 'relative', top: '-6px', left: '3px' }}>00</span></Typography>
              <Typography sx={{ fontSize: '22px', lineHeight: '41px', fontWeight: '900' }}>12<span style={{ fontSize: '14px', position: 'relative', top: '-6px', left: '3px' }}>00</span> - 22<span style={{ fontSize: '14px', position: 'relative', top: '-6px', left: '3px' }}>00</span></Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box>
        <Button
          sx={{ background: '#00CAE6', fontWeight: '600', textTransform: 'uppercase', width: '173px', color: 'white', marginTop: '20px', marginRight: '20px' }}
          variant="contained"
          onClick={() => history.push('/order')}
        >
          Zamów online
        </Button>
        <Button
          sx={{ border: '1px solid #00CAE6', background: 'transparent', fontWeight: '600', textTransform: 'uppercase', width: '173px', color: '#00CAE6', marginTop: '20px' }}
          variant="contained"
        >
          <a
            style={{ textDecoration: 'none' }}
            href="tel:+48943559929"
            target="_blank"
            rel="noreferrer"
          >
            Zadzwoń
          </a>
        </Button>
      </Box>

      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1167.2514508411477!2d16.1780940923531!3d54.18887906117464!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4701cd0c6885cc75%3A0x813e6f193ac93e03!2sPizzeria%20UNO%20Koszalin!5e0!3m2!1sen!2spl!4v1671382316931!5m2!1sen!2spl"
        style={{ width: '100%', height: '450px', marginTop: '20px', marginBottom: '20px' }} allowFullScreen=""></iframe>
      <Button
        sx={{ background: '#00CAE6', fontWeight: '600', textTransform: 'uppercase', width: '120px', color: 'white' }}
        variant="contained"
        onClick={() => window.open('https://goo.gl/maps/JJDSGUKLm6WR9byb9', '_blank')}
      >
        Nawiguj
      </Button>
    </Box>
  )
}

export default ContactPage
