import React, { useEffect, useRef, useState } from 'react'
import Input from '../../atoms/Forms/Input/Input'
import Button from '../../atoms/Forms/Button/Button'
import InputPassword from '../../atoms/Forms/InputPassword/InputPassword'
import { Formik } from 'formik'
import * as yup from 'yup'
import ValidationHelper from '../../../helpers/ValidationHelper/ValidationHelper'
import PropTypes from 'prop-types'
import {
  Checkbox,
  FormControlLabel,
  TextareaAutosize,
  TextField,
  useTheme,
  Autocomplete,
  MenuItem, Select, InputLabel, FormControl
} from '@mui/material'
import LocalStorageHelper from '../../../helpers/LocalStorageHelper/LocalStorageHelper'
import Checkboxes from '../../atoms/Forms/Checkboxes/Checkboxes'
import InputMask from 'react-input-mask'
import { useHistory } from 'react-router'

const FormGenerator = (props) => {
  const { data, submit } = props
  const [date, setDate] = useState(null)
  const formRef = useRef()
  const theme = useTheme()
  const history = useHistory()
  const style = {
    elements: {
      padding: '10px 0',
      display: 'flex',
      borderColor: 'white',
      alignItems: 'start',
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
      margin: '0 auto',
      textAlign: 'left',
      color: 'white',
    },
    elementsError: {
      padding: '20px 0',
      maxWidth: '320px',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      flex: 1,
      border: '1px solid red',
      justifyContent: 'center',
      width: '100%',
      margin: '0 auto',
      textAlign: 'left'
    },
    helperText: {
      color: 'white',
      fontWeight: 400,
      fontSize: '.75rem',
      letterSpacing: '.03333em',
      marginTop: '3px',
      marginRight: '14px',
      marginBottom: 0,
      marginLeft: '14px'
    }
  }
  const yepSchema = data?.elements?.reduce(ValidationHelper.createYupSchema, {})
  const validationSchema = yup.object().shape(yepSchema)
  const initialValues = {}
  const generateInitialValues = () => {
    const tempInit = {}
    data?.elements?.map((el) => {
      if (el?.name === 'games') {
        el?.values.map(checkbox => {
          tempInit[checkbox?.value] = false
          return true
        })
      } else if (el.type !== 'button') initialValues[el.name] = el?.value || el?.defaultValue
      return true
    })
  }
  const handleOpenRegulation = (e, el) => {
    e.preventDefault()
    if (el?.link?.length > 0) {
      window.open(el.link, '_blank')
    }
  }
  useEffect(() => {
    generateInitialValues()
  }, [data])
  useEffect(() => {
    if (date?.length > 0) {
      formRef.current.values.date = date
    }
  }, [date])
  return (
    <>
      {
        data && initialValues && (
          <Formik
            innerRef={formRef}
            onSubmit={(e) => submit(e)}
            validationSchema={validationSchema}
            initialValues={initialValues}
            validateOnMount
          >
            {({ values, touched, errors, handleSubmit, handleChange, handleBlur, setFieldValue }) => (
              <form autoComplete="off" style={{ width: '100%' }}>
                <div style={style.elements}>
                  {
                    data?.elements?.map((el, index) => {
                      if ((el.superuser && LocalStorageHelper.get('user')?.role === 'superuser') || !el.superuser) {
                        switch (el.type) {
                          case 'button':
                            return (
                              <Button
                                sx={{ marginTop: '0', width: '100%', fontWeight: '600', background: '#00CAE6', color: 'white' }}
                                key={index}
                                variant="contained"
                                fullWidth
                                onClick={() => handleSubmit(values)}
                              >
                                {el?.value}
                              </Button>
                            )
                          case 'checkbox':
                            console.log(el.link)
                            return (
                              <FormControlLabel
                                key={index}
                                label={(
                                  <span
                                    onClick={(e) => handleOpenRegulation(e, el)}
                                    dangerouslySetInnerHTML={{ __html: el.label }}
                                  />
                                )}
                                sx={{
                                  marginBottom: '15px',
                                  textDecoration: el?.link && 'underline',
                                  cursor: el?.link && 'pointer',
                                }}
                                control={(
                                  <Checkbox
                                    defaultChecked={el?.defaultValue}
                                    name={el.name}
                                    onChange={(e) => setFieldValue(el.name, e?.target?.checked)}
                                    onBlur={(e) => setFieldValue(el.name, e?.target?.checked)}
                                    onKeyUp={(e) => setFieldValue(el.name, e?.target?.checked)}
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      color: theme.palette.primary.main,
                                      '&.Mui-checked': {
                                        color: theme.palette.primary.main,
                                      },
                                    }}
                                    />
                                )}
                              />
                            )
                          case 'password':
                            return (
                              <InputPassword
                                key={index}
                                name={el?.name}
                                label={el?.label}
                                required={el?.required}
                                value={values[el.name] || ''}
                                disabled={el?.disabled}
                                onBlur={handleBlur}
                                onKeyUp={handleBlur}
                                onChange={handleChange}
                                helperText={el?.helperText}
                                defaultValue={el?.defaultValue}
                                error={touched[el?.name] && errors[el?.name]}
                              />
                            )
                          case 'phone':
                            return (
                              <InputMask
                                key={index}
                                mask="999 999 999"
                                value={values[el?.name] || initialValues[el?.name] || ''}
                                disabled={el?.disabled}
                                maskChar=" "
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onKeyUp={handleBlur}
                              >
                                {
                                  () => (
                                    <TextField
                                      key={index}
                                      sx={{ marginBottom: '10px', width: '100%' }}
                                      name={el?.name}
                                      type={el?.type}
                                      disabled={el?.disabled}
                                      label={`${el?.label} *`}
                                      value={values[el?.name]}
                                      error={touched[el?.name] && errors[el.name]}
                                    />
                                  )
                                }
                              </InputMask>
                            )
                          case 'date':
                            return (
                              <Input
                                key={index}
                                name={el.name}
                                type="date"
                                label={el.label}
                                value={values[el.name]}
                                helperText={el.helperText}
                                disabled={el.disabled}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onKeyUp={handleBlur}
                                required={el?.required}
                                defaultValue={el?.defaultValue}
                                error={touched[el.name] && errors[el.name]}
                              />
                            )
                          case 'textarea':
                            return (
                              <TextareaAutosize
                                key={index}
                                name={el?.name}
                                type={el?.type}
                                placeholder={el?.label + ' *'}
                                value={values[el.name] || ''}
                                disabled={el?.disabled}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onKeyUp={handleBlur}
                                defaultValue={el?.defaultValue}
                                error={touched[el.name] && errors[el.name]}
                                style={{
                                  marginTop: '5px',
                                  marginBottom: '15px',
                                  width: 'calc(100% - 28px)',
                                  animationDuration: '10ms',
                                  padding: '16.5px 14px',
                                  height: '10em',
                                  fontSize: '16px',
                                  borderRadius: '4px',
                                }}
                              />
                            )
                          case 'autocomplete':
                            return (
                              <Autocomplete
                                key={index}
                                sx={{ marginBottom: '10px' }}
                                disableClearable
                                options={el?.options}
                                onChange={(e) => setFieldValue(el?.name, e.target.value)}
                                onKeyUp={(e) => setFieldValue(el?.name, e.target.value)}
                                onBlur={(e) => setFieldValue(el?.name, e.target.value)}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    name={el?.name}
                                    label={el?.label}
                                  />
                                )}
                              />
                            )
                          case 'hour':
                            return (
                              <TextField
                                key={index}
                                type="time"
                                name={el?.name}
                                label={el?.label}
                                value={values[el.name] || ''}
                                helperText={el?.helperText}
                                disabled={el?.disabled}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onKeyUp={handleBlur}
                                defaultValue={el?.defaultValue}
                                error={touched[el.name] && errors[el.name]}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  step: 300,
                                }}
                                sx={{
                                  marginTop: '5px',
                                  marginBottom: '15px',
                                }}
                              />
                            )
                          case 'select':
                            return (
                              <FormControl
                                key={index}
                                sx={{
                                  margin: '0',
                                  width: '100%',
                                  height: '56px',
                                  marginBottom: '20px'
                                }}
                              >
                                <InputLabel>{el.label}</InputLabel>
                                <Select
                                  defaultValue={el.defaultValue}
                                  value={values[el.name] || el.value || ''}
                                  label={el.label}
                                  name={el.name}
                                  sx={{
                                    height: '56px',
                                    color: 'white',
                                  }}
                                  onChange={(e) => {
                                    setFieldValue(el.name, e?.target?.value)
                                    if (el.onChange) el.onChange(e)
                                  }}
                                >
                                  {
                                    el?.placeholder && (
                                      <MenuItem disabled value="">
                                        <em>{el.placeholder}</em>
                                      </MenuItem>
                                    )
                                  }
                                  {
                                    el?.items && el.items.map((menuItem) => (
                                      <MenuItem key={menuItem.value} value={menuItem.value}>
                                        {menuItem.name}
                                      </MenuItem>
                                    ))
                                  }
                                </Select>
                              </FormControl>
                            )
                          case 'checkboxes':
                            return (
                              <Checkboxes
                                keyIndex={index}
                                label={el?.label}
                                values={el?.values}
                                setFieldValue={setFieldValue}
                              />
                            )
                          default:
                            return (
                              <Input
                                key={index}
                                name={el.name}
                                type={el.type}
                                label={el.label}
                                value={values[el.name] || ''}
                                helperText={el.helperText}
                                disabled={el.disabled}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onKeyUp={handleBlur}
                                required={el?.required}
                                defaultValue={values[el.name]}
                                error={touched[el.name] && errors[el.name]}
                              />
                            )
                        }
                      }
                      return true
                    })
                  }
                </div>
              </form>
            )}
          </Formik>
        )
      }
    </>
  )
}

FormGenerator.propTypes = {
  data: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired,
}

export default FormGenerator
