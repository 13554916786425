import { BrowserRouter as Router, Switch } from 'react-router-dom'
import MainLayout from './layouts/MainLayout/MainLayout'
import { Redirect, useHistory } from 'react-router'
import './assets/scss/App.scss'
import { PublicRoute } from './router/PublicRoute/PublicRoute'
import LoginPage from './pages/LoginPage/LoginPage'
import SimpleLayout from './layouts/SimpleLayout/SimpleLayout'
import { PrivateRoute } from './router/PrivateRoute/PrivateRoute'
import HomePage from './pages/HomePage/HomePage'
import PreviewLayout from './layouts/PreviewLayout/PreviewLayout'
import RegisterPage from './pages/RegisterPage/RegisterPage'
import ProfilePage from './pages/ProfilePage/ProfilePage'
import AdminPage from './pages/AdminPage/AdminPage'
import RememberPage from './pages/RememberPage/RememberPage'
import LangHelper from './helpers/LangHelper/LangHelper'
import NewsPage from './pages/NewsPage/NewsPage'
import BuyPage from './pages/BuyPage/BuyPage'
import OrderPage from './pages/OrderPage/OrderPage'
import CodesPage from './pages/CodesPage/CodesPage'
import ContactPage from './pages/ContactPage/ContactPage'
import TourPage from './pages/TourPage/TourPage'
import DeletePage from './pages/DeletePage/DeletePage'
import PolicyPage from './pages/PolicyPage/PolicyPage'
import RegulationPage from './pages/RegulationPage/RegulationPage'

const App = () => {
  const history = useHistory()
  LangHelper.setDefaultLanguage(history)
  return (
    <Router basename={'/'}>
      <Switch>
        <PublicRoute exact path={'/'} component={() => <Redirect to={'/login'} />} layout={SimpleLayout} />
        <PublicRoute path={'/home'} component={HomePage} layout={PreviewLayout} />
        <PublicRoute path={'/login'} component={LoginPage} layout={SimpleLayout} />
        <PublicRoute path={'/register'} component={RegisterPage} layout={SimpleLayout} />
        <PublicRoute path={'/reset'} component={RememberPage} layout={SimpleLayout} />
        <PublicRoute path={'/policy'} component={PolicyPage} layout={PreviewLayout} />
        <PublicRoute path={'/regulation'} component={RegulationPage} layout={PreviewLayout} />
        <PrivateRoute path={'/admin'} component={AdminPage} layout={MainLayout} />
        <PrivateRoute path={'/tours'} component={TourPage} layout={MainLayout} />
        <PrivateRoute path={'/dashboard'} component={NewsPage} layout={MainLayout} />
        <PrivateRoute path={'/buy'} component={BuyPage} layout={MainLayout} />
        <PrivateRoute path={'/codes'} component={CodesPage} layout={MainLayout} />
        <PrivateRoute path={'/contact'} component={ContactPage} layout={MainLayout} />
        <PrivateRoute path={'/order'} component={OrderPage} layout={MainLayout} />
        <PrivateRoute path={'/profile'} component={ProfilePage} layout={MainLayout} />
        <PrivateRoute path={'/deleteAccount'} component={DeletePage} layout={MainLayout} />
      </Switch>
    </Router>
  )
}

export default App
